import React, { memo, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

// Material UI
import { Typography, Grid, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../contexts/StoreContext';
import useMenu from '../../hooks/useMenu';
import GoalCard from './GoalCard/GoalCard';

const Plan = memo(({ planId, selected }) => {
  const classes = useStyles();
  const { getPlan, updatePlan, deletePlan, getGoal, updateGoal, Scope, jsonToArray } = useContext(
    StoreContext
  );

  const { menu, onMenuClick } = useMenu([{ label: 'Delete', onClick: () => deletePlan(planId) }]);

  const plan = getPlan(planId);
  if (!plan) return null;
  const { year, yGoalIds, q1GoalIds, q2GoalIds, q3GoalIds, q4GoalIds } = plan;
  const topBarColor = selected ? 'secondary' : 'grey';

  return (
    <div className={classes.grow} key={plan.year}>
      <div className={classes.yearAndMenu}>
        {/* Year */}
        <Typography variant="h6">{year}</Typography>
        
        {/* Menu button */}
        <IconButton onClick={onMenuClick} className={classes.menuButton}>
          <MoreVertIcon color="disabled" />
        </IconButton>
        {menu}
      </div>

      {/* Goals */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={3}>
          {/* 1-Year Goals */}
          <Grid item xs={12}>
            <GoalCard
              title="1-Year Goals"
              goalIds={jsonToArray(yGoalIds)}
              planId={planId}
              scope={Scope.Y}
              topBarColor={topBarColor}
            />
          </Grid>

          {/* 1st Quarter Goals */}
          <Grid item xs={12} sm={12}>
            <GoalCard
              title="1st Quarter Goals"
              goalIds={jsonToArray(q1GoalIds)}
              planId={planId}
              scope={Scope.Q1}
              topBarColor={topBarColor}
            />
          </Grid>

          {/* 2nd Quarter Goals */}
          <Grid item xs={12} sm={12}>
            <GoalCard
              title="2nd Quarter Goals"
              goalIds={jsonToArray(q2GoalIds)}
              planId={planId}
              scope={Scope.Q2}
              topBarColor={topBarColor}
            />
          </Grid>

          {/* 3rd Quarter Goals */}
          <Grid item xs={12} sm={12}>
            <GoalCard
              title="3rd Quarter Goals"
              goalIds={jsonToArray(q3GoalIds)}
              planId={planId}
              scope={Scope.Q3}
              topBarColor={topBarColor}
            />
          </Grid>

          {/* 4th Quarter Goals */}
          <Grid item xs={12} sm={12}>
            <GoalCard
              title="4th Quarter Goals"
              goalIds={jsonToArray(q4GoalIds)}
              planId={planId}
              scope={Scope.Q4}
              topBarColor={topBarColor}
            />
          </Grid>
        </Grid>
      </DragDropContext>
    </div>
  );

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    // Handle goals
    if (type === 'goal') {
      const goalId = draggableId;
      const [sourcePlanId, sourceScope] = source.droppableId.split('|');
      const [destinationPlanId, destinationScope] = destination.droppableId.split('|');
      const sourcePlan = getPlan(sourcePlanId);
      const destinationPlan = getPlan(destinationPlanId);
      const sourceScopedGoalIds = sourceScope.toLowerCase() + 'GoalIds';
      const destinationScopedGoalIds = destinationScope.toLowerCase() + 'GoalIds';

      const newSourceGoalIds = jsonToArray(sourcePlan[sourceScopedGoalIds]);

      // Remove goal from source
      newSourceGoalIds.splice(source.index, 1);

      // If source and destination are the same
      if (sourcePlan === destinationPlan && sourceScope === destinationScope) {
        // Add goal in the new spot
        newSourceGoalIds.splice(destination.index, 0, goalId);
        updatePlan(sourcePlan, { [sourceScopedGoalIds]: JSON.stringify(newSourceGoalIds) });
      }

      // If destination is different from source
      else {
        const newDestinationGoalIds = jsonToArray(destinationPlan[destinationScopedGoalIds]);
        const goal = getGoal(goalId);

        // Add goal in the new spot
        newDestinationGoalIds.splice(destination.index, 0, goalId);

        if (sourcePlan === destinationPlan) {
          updatePlan(sourcePlan, {
            [sourceScopedGoalIds]: JSON.stringify(newSourceGoalIds),
            [destinationScopedGoalIds]: JSON.stringify(newDestinationGoalIds),
          });
        } else {
          updatePlan(
            [sourcePlan, destinationPlan],
            [
              { [sourceScopedGoalIds]: JSON.stringify(newSourceGoalIds) },
              { [destinationScopedGoalIds]: JSON.stringify(newDestinationGoalIds) },
            ]
          );
        }
        updateGoal(goal, { planId: destinationPlanId, scope: destinationScope });
      }
    }
  }
});

export default Plan;
