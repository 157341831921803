import React, { memo, useState, useContext } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// Material UI
import {
  List,
  ListItem,
  ListSubheader,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../../contexts/StoreContext';
import useMenu from '../../../../hooks/useMenu';
import AreaListItem from './AreaListItem/AreaListItem';
import CustomInput from '../../../../components/UI/CustomInput/CustomInputWithButtons';

const AreaList = memo(({ index, areaId, edit }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const { getArea, updateArea, deleteArea, addCategory, jsonToArray } = useContext(StoreContext);

  const { menu, isMenuOpen, onMenuClick } = useMenu([
    // { label: 'Rename', onClick: () => {} },
    // { label: 'Share', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteArea(area.id) },
  ]);

  const area = getArea(areaId);
  if (!area) return null;

  return (
    <>
      <Draggable draggableId={areaId} index={index}>
        {(areaProvided, snapshot) => (
          <Paper
            {...areaProvided.draggableProps}
            innerRef={areaProvided.innerRef}
            square={!snapshot.isDragging}
            elevation={snapshot.isDragging ? 4 : 0}
          >
            <Droppable droppableId={areaId} type="category">
              {(provided) => (
                <List
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                  dense
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      style={{ display: 'flex', paddingRight: 48 }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      {/* Area name */}
                      <div style={{ flexGrow: 1 }}>
                        <CustomInput
                          variant="inherit"
                          inputStyle={{ fontSize: '0.875rem', marginTop: 12 }}
                          value={area.name}
                          placeholder="New area"
                          onChange={(value) => updateArea(area, { name: value })}
                        />
                      </div>

                      {/* Area menu button */}
                      {(edit || hover || isMenuOpen) && (
                        <IconButton onClick={onMenuClick}>
                          <MoreVertIcon fontSize="small" color="disabled" />
                        </IconButton>
                      )}

                      {/* Area drag handle */}
                      <ListItemSecondaryAction
                        className={classes.dragHandle}
                        {...areaProvided.dragHandleProps}
                      >
                        {(edit || hover || isMenuOpen) && <DragHandleIcon color="disabled" />}
                      </ListItemSecondaryAction>
                    </ListSubheader>
                  }
                >
                  {/* Loop categories */}
                  {jsonToArray(area.categoryIds)?.map((categoryId, index) => (
                    <AreaListItem
                      key={categoryId}
                      categoryId={categoryId}
                      index={index}
                      edit={edit}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>

            {edit && (
              <List>
                <ListItem>
                  <Button size="small" onClick={() => addCategory({ areaId, name: '' })}>
                    <AddIcon className={classes.leftIcon} /> Add a category
                  </Button>
                </ListItem>
              </List>
            )}

            <Divider />
          </Paper>
        )}
      </Draggable>

      {menu}
    </>
  );
});

export default AreaList;
