import React, { memo, useContext } from 'react';
import moment from 'moment';
import clsx from 'clsx';

// Material UI
import {
  List,
  ListSubheader,
  ListItem,
  Typography,
  ButtonGroup,
  Button,
  Badge,
  IconButton,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../../contexts/AppStateContext';
import { StoreContext } from '../../../../contexts/StoreContext';

const TimeScope = memo(() => {
  const classes = useStyles();
  const { timeScope, selectedDate, updateAppState } = useContext(AppStateContext);
  const { blocks } = useContext(StoreContext);

  const eventCountPerDay = blocks
    .filter((block) => block.scheduledDate)
    .reduce((events, block) => {
      const date = moment(block.scheduledDate).format('YYYY-MM-DD');
      events[date] = (events[date] || 0) + 1;
      return events;
    }, {});

  const overdueCountPerDay = blocks
    .filter((block) => block.scheduledDate)
    .reduce((events, block) => {
      const date = moment(block.scheduledDate).format('YYYY-MM-DD');
      events[date] =
        (events[date] || 0) +
        (moment(block.scheduledDate).isBefore(moment().startOf('day')) && !block.completedDate
          ? 1
          : 0);
      return events;
    }, {});

  const renderDay = (day, selectedDateNotUsed, isInCurrentMonth) => {
    const numberOfEvents = isInCurrentMonth && eventCountPerDay[day.format('YYYY-MM-DD')];
    const overdue = isInCurrentMonth && overdueCountPerDay[day.format('YYYY-MM-DD')];

    const ranges = {
      day: 0,
      week: 6,
      month: moment(selectedDate).daysInMonth() - 1,
    };

    const start = moment(selectedDate).startOf('day');
    const end = moment(start).add(ranges[timeScope], 'days').endOf('day');

    const dayIsBetween = day.isBetween(start, end, undefined, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !isInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !isInCurrentMonth && dayIsBetween,
    });

    const dayComponent = (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {day.date()} </span>
        </IconButton>
      </div>
    );

    let Day = day.isSameOrAfter(new Date(), 'day') ? (
      <Badge badgeContent={numberOfEvents || 0} overlap="circle" color="secondary">
        {dayComponent}
      </Badge>
    ) : (
      <PastBadge overlap="circle" badgeContent={numberOfEvents || 0}>
        {dayComponent}
      </PastBadge>
    );

    if (overdue) {
      Day = (
        <OverdueBadge color="primary" overlap="rectangle" badgeContent=" " variant="dot">
          {Day}
        </OverdueBadge>
      );
    }

    return Day;
  };

  return (
    <>
      <List
        dense
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Typography variant="overline">Time Scope</Typography>
          </ListSubheader>
        }
      >
        <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Time Scope buttons */}
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              className={timeScope === 'day' && classes.selectedTimeScope}
              onClick={() => updateAppState({ timeScope: 'day' })}
            >
              Day
            </Button>
            <Button
              className={timeScope === 'week' && classes.selectedTimeScope}
              onClick={() => updateAppState({ timeScope: 'week' })}
            >
              Week
            </Button>
            <Button
              className={timeScope === 'month' && classes.selectedTimeScope}
              onClick={() => updateAppState({ timeScope: 'month' })}
            >
              Month
            </Button>
          </ButtonGroup>

          {/* Today button */}
          <Button
            onClick={() =>
              updateAppState({ selectedDate: moment().startOf('day').format(), today: true })
            }
          >
            Today
          </Button>
        </ListItem>
      </List>

      {/* Calendar */}
      <DatePicker
        label="Calendar"
        variant="static"
        disableToolbar
        format="YYYY-MM-DD"
        margin="normal"
        autoOk
        value={selectedDate}
        onChange={(momentDate) =>
          updateAppState({
            selectedDate: momentDate.startOf('day').format(),
            today: momentDate.isSame(moment(), 'day'),
          })
        }
        renderDay={renderDay}
      />
    </>
  );
});

const PastBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#888888',
    color: 'white',
  },
}))(Badge);

const OverdueBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#cc0000',
    color: 'white',
    right: -3,
    top: -3,
  },
}))(Badge);

export default TimeScope;
