import React, { useState } from 'react';
import moment from 'moment';

// Material UI
import { Menu, MenuItem, Typography, IconButton, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation as CalendarIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { prettyDate } from '../../../utils/utils';

const CustomDatePicker = ({ date, showDate, onChange, buttonContent, color, size }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const selectDate = (newDate) => {
    onChange && onChange(newDate);
    closeMenu();
  };

  return (
    <div className={classes.root}>
      {/* Date */}
      {showDate && date && (
        <Typography variant="body1" onClick={openMenu} color={color || 'inherit'}>
          {prettyDate(date)}
        </Typography>
      )}

      {/* Button */}
      {buttonContent ? (
        <Button onClick={openMenu} color={color || 'inherit'} size={size || 'medium'}>
          {buttonContent}
        </Button>
      ) : (
        <IconButton onClick={openMenu} color={color || 'inherit'}>
          <CalendarIcon />
        </IconButton>
      )}

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeMenu}
        classes={{ paper: classes.menu }}
      >
        <MenuItem onClick={() => selectDate(moment())}>Today</MenuItem>
        <MenuItem onClick={() => selectDate(moment().add(1, 'day'))}>Tomorrow</MenuItem>
        <MenuItem onClick={() => selectDate(moment().startOf('isoWeek').add(1, 'week'))}>
          Next Week
        </MenuItem>
        <DatePicker
          label="Date"
          variant="static"
          disableToolbar
          clearable
          format="YYYY-MM-DD"
          margin="normal"
          autoOk
          value={date || null}
          onChange={selectDate}
        />
      </Menu>
    </div>
  );
};

export default CustomDatePicker;
