const resizeImage = async (file, maxSize) => {
  // Load the image
  const dataURL = await readFile(file);
  const image = await loadImage(dataURL);

  // Resize image (with canvas) and generate a blob
  const canvas = createCanvas(image, maxSize);
  const blob = await imageToBlob(image, canvas);

  // Create a file from the blob
  return {
    file: new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }),
    width: canvas.width,
    height: canvas.height,
  };
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const loadImage = (file) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = file;
  });
};

const createCanvas = (image, maxSize) => {
  const canvas = document.createElement('canvas');
  if (image.width > image.height) {
    const width = maxSize;
    const scaleFactor = width / image.width;
    canvas.width = width;
    canvas.height = image.height * scaleFactor;
  } else {
    const height = maxSize;
    const scaleFactor = height / image.height;
    canvas.width = image.width * scaleFactor;
    canvas.height = height;
  }
  return canvas;
};

const imageToBlob = (image, canvas) => {
  return new Promise((resolve) => {
    const context = canvas.getContext('2d');
    context.imageSmoothingQuality = 'high';
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    context.canvas.toBlob(async (blob) => resolve(blob), 'image/jpeg', 0.75);
  });
};

export default resizeImage;
