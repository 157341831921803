import React, { memo, useContext } from 'react';

// Material UI
import {
  List,
  ListSubheader,
  ListItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// Lyfeplan
import { AppStateContext } from '../../../../contexts/AppStateContext';

const Options = memo(() => {
  const { showCompleted, showCompletedExceptions, minimizeBlocks, updateAppState } = useContext(
    AppStateContext
  );

  return (
    <List
      dense
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="overline">Options</Typography>
        </ListSubheader>
      }
    >
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCompleted === true}
                indeterminate={!!showCompletedExceptions?.length}
                onChange={() =>
                  updateAppState({ showCompleted: !showCompleted, showCompletedExceptions: [] })
                }
                disableRipple
              />
            }
            label="Show completed"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={minimizeBlocks || false}
                onChange={() => updateAppState({ minimizeBlocks: !minimizeBlocks })}
                disableRipple
              />
            }
            label="Minimize blocks"
          />
        </FormGroup>
      </ListItem>
    </List>
  );
});

export default Options;
