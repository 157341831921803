import React, { useState, useContext } from 'react';
import moment from 'moment';

// Material UI
import { Typography, Divider, Button, IconButton, Tooltip, Checkbox } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { StoreContext } from '../../../contexts/StoreContext';
import useMenu from '../../../hooks/useMenu';
import CustomCard from '../../UI/CustomCard/NewCustomCard';
import CustomInput from '../../UI/CustomInput/CustomInputWithButtons';
import DroppableList from '../../UI/DroppableList/DroppableList';
import DraggableListItem from '../../UI/DroppableList/DraggableListItem/DraggableListItem';
import BlockDatePicker from '../BlockDatePicker/BlockDatePicker';

const ProjectBlock = ({ blockId, dragHandleProps, showDate }) => {
  const classes = useStyles();
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const { minimizeBlocks, showCompleted } = useContext(AppStateContext);
  const { getBlock, addBlock, updateBlock, deleteBlock, BlockType } = useContext(StoreContext);

  const { menu, onMenuClick } = useMenu([
    { label: 'Schedule', onClick: () => {} },
    { label: 'Toggle completed', onClick: () => onBlockToggleCompleted(blockId) },
    { label: 'Hide completed', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteBlock(blockId) },
  ]);

  const block = getBlock(blockId);
  if (!block) return null;

  const onBlockToggleCompleted = () => {
    updateBlock(block, { completedDate: block.completedDate ? null : moment().format() });
  };

  return (
    <CustomCard
      topBarColor="tertiary"
      title="Project"
      options={
        <>
          {/* Date picker */}
          <BlockDatePicker
            blockId={blockId}
            block={block}
            showDate={showDate}
            isOpenSchedule={isOpenSchedule}
            setIsOpenSchedule={setIsOpenSchedule}
            updateBlock={updateBlock}
          />

          {/* Menu button */}
          <IconButton color="inherit" onClick={onMenuClick}>
            <MoreVertIcon />
          </IconButton>
          {menu}

          {/* Drag handle */}
          {dragHandleProps && (
            <div {...dragHandleProps} className={classes.dragHandleIcon}>
              <DragHandleIcon style={{ display: 'block' }} />
            </div>
          )}
        </>
      }
      noPadding
      minimized={minimizeBlocks}
    >
      <div className={classes.cardPadding}>
        {/* Goals */}
        <Typography variant="body1" component="div">
          Related Goals: {block.goalIds.join(', ')}
        </Typography>

        {/* Name */}
        <Typography variant="body1" component="div">
          <CustomInput
            value={block.name}
            placeholder="Name"
            onChange={(value) => updateBlock(block, { name: value })}
          />
        </Typography>

        {/* Result */}
        <Typography variant="body1" component="div" className={classes.result}>
          <CustomInput
            value={block.result}
            placeholder="Result (what)"
            onChange={(value) => updateBlock(block, { result: value })}
          />
        </Typography>

        {/* Purpose */}
        {!minimizeBlocks && (
          <Typography variant="body1" component="div" className={classes.purpose}>
            <CustomInput
              value={block.purpose}
              placeholder="Purpose (why)"
              onChange={(value) => updateBlock(block, { purpose: value })}
            />
          </Typography>
        )}
      </div>

      {/* Result items */}
      {!minimizeBlocks && (
        <>
          <Divider />
          <DroppableList droppableId={blockId} droppableType="block" outerDragDropContext>
            {block.blockIds.map((blockId, index) => {
              const result = getBlock(blockId);
              if (!result) {
                console.log(`Result block with id ${blockId} not found.`);
                return null;
              }
              if (result.completedDate && !showCompleted) return null;

              return (
                <DraggableListItem key={result.id} draggableId={result.id} index={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip
                      title={
                        result.completedDate
                          ? `Completed on ${moment(result.completedDate).format('YYYY-MM-DD')}`
                          : ''
                      }
                      placement="right"
                    >
                      <Checkbox
                        edge="start"
                        onClick={() =>
                          updateBlock({
                            ...result,
                            completedDate: result.completedDate ? null : moment().format(),
                          })
                        }
                        checked={!!result.completedDate}
                      />
                    </Tooltip>
                    <CustomInput
                      value={result.result}
                      placeholder="New result"
                      onChange={(value) => updateBlock(block, { ...result, result: value })}
                      style={{ flexGrow: 1 }}
                      textClassName={!!result.completedDate && classes.strike}
                    />
                    <IconButton onClick={onMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </DraggableListItem>
              );
            })}
          </DroppableList>
        </>
      )}

      <div className={classes.cardPadding}>
        {/* Add result button */}
        <Button
          size="small"
          onClick={() => addBlock({ type: BlockType.RESULT, parentBlockId: blockId })}
        >
          <AddIcon className={classes.leftIcon} /> Add Result
        </Button>
      </div>
    </CustomCard>
  );
};

export default ProjectBlock;
