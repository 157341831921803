import React, { memo, useState, useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';

// Material UI
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import { DragHandle as DragHandleIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../../../contexts/AppStateContext';
import { StoreContext } from '../../../../../contexts/StoreContext';
import useMenu from '../../../../../hooks/useMenu';

const AreaListItem = memo(({ categoryId, index, edit }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const { selectedCategoryId, updateAppState } = useContext(AppStateContext);
  const { getCategory, deleteCategory } = useContext(StoreContext);

  const { menu, isMenuOpen, onMenuClick } = useMenu([
    // { label: 'Rename', onClick: () => {} },
    // { label: 'Share', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteCategory(category.id) },
  ]);

  const category = getCategory(categoryId);
  if (!category) return null;
  const selected = selectedCategoryId === categoryId;

  return (
    <>
      <Draggable draggableId={categoryId} index={index}>
        {(provided, snapshot) => (
          <Paper
            {...provided.draggableProps}
            innerRef={provided.innerRef}
            square={!snapshot.isDragging}
            elevation={snapshot.isDragging ? 4 : 0}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <ListItem
              button
              onClick={() => {
                // Update selectedCategoryId
                updateAppState({
                  selectedCategoryId: categoryId,
                  selectedPlanId: null,
                  drawerOpen: false,
                });
              }}
              selected={selected}
              classes={{ selected: classes.selectedListItem }}
            >
              <ListItemText
                style={{ display: 'flex' }}
                disableTypography
                primary={
                  <>
                    {/* Category name */}
                    <Typography variant="body2" style={{ flexGrow: 1 }}>
                      {category.name || 'New category'}
                    </Typography>

                    {/* Menu button */}
                    {(hover || edit || isMenuOpen) && (
                      <IconButton
                        onClick={onMenuClick}
                        style={{ marginTop: -12, marginBottom: -12 }}
                      >
                        <MoreVertIcon fontSize="small" color="disabled" />
                      </IconButton>
                    )}
                  </>
                }
              />

              {/* Drag handle */}
              <ListItemSecondaryAction {...provided.dragHandleProps}>
                {(hover || edit || isMenuOpen) && <DragHandleIcon color="disabled" />}
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        )}
      </Draggable>

      {menu}
    </>
  );
});

export default AreaListItem;
