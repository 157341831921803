// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Scope = {
  "Y": "Y",
  "Q1": "Q1",
  "Q2": "Q2",
  "Q3": "Q3",
  "Q4": "Q4"
};

const BlockType = {
  "CAPTURE": "CAPTURE",
  "RESULT": "RESULT",
  "PROJECT": "PROJECT"
};

const { Profile, Area, Category, VisionBoardImage, Plan, Goal, Block, Action } = initSchema(schema);

export {
  Profile,
  Area,
  Category,
  VisionBoardImage,
  Plan,
  Goal,
  Block,
  Action,
  Scope,
  BlockType
};