import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  projectName: {
    marginTop: '20px',
    marginBottom: '20px',
    flexGrow: 1,
  },
}));
