import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  dragHandle: {
    top: '70%'
  }
}));
