import React, { memo, useContext } from 'react';

// Material UI
import { Container } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../contexts/AppStateContext';
import ProjectsDrawer from './ProjectsDrawer/ProjectsDrawer';
import Project from '../../components/Project/Project';

const Projects = memo(() => {
  const classes = useStyles();
  const { selectedProjectId } = useContext(AppStateContext);

  return (
    <>
      <ProjectsDrawer />

      <Container>
        <div className={classes.toolbar} />
        <Project projectId={selectedProjectId} />
      </Container>
    </>
  );
});

export default Projects;
