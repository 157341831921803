import { useContext } from 'react';

// Lyfeplan
import { StoreContext } from '../contexts/StoreContext';

// Drop Action Hook
const useDropAction = () => {
  const { getBlock, updateBlock, getAction, updateAction, jsonToArray } = useContext(StoreContext);

  function dropAction(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const cleanId = (id) => id.split('|')[0];

    // Handle actions
    if (type === 'action' || type === 'completedAction') {
      const idsField = type === 'action' ? 'actionIds' : 'completedActionIds';

      const sourceBlock = getBlock(cleanId(source.droppableId));
      const destinationBlock = getBlock(cleanId(destination.droppableId));
      const newSourceActionIds = jsonToArray(sourceBlock[idsField]);

      // Remove action from source block
      newSourceActionIds.splice(source.index, 1);

      // If source and destination block are the same
      if (sourceBlock === destinationBlock) {
        // Add action in the new spot
        newSourceActionIds.splice(destination.index, 0, draggableId);
        updateBlock(sourceBlock, { [idsField]: JSON.stringify(newSourceActionIds) });
      }

      // If destination is different from source
      else {
        const newDestinationActionIds = jsonToArray(destinationBlock[idsField]);
        const action = getAction(draggableId);

        // Add action in the new spot
        newDestinationActionIds.splice(destination.index, 0, draggableId);

        updateBlock(
          [sourceBlock, destinationBlock],
          [
            { [idsField]: JSON.stringify(newSourceActionIds) },
            { [idsField]: JSON.stringify(newDestinationActionIds) },
          ]
        );
        updateAction(action, { blockId: destinationBlock.id });
      }
    }
  }

  return dropAction;
};

export default useDropAction;
