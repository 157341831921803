import React, { memo, useState, useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// Material UI
import {
  // NO MEGA-PROJECT (TEMPORARY)
  // Fab,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import {
  Add as AddIcon,
  // NO MEGA-PROJECT (TEMPORARY)
  // Edit as EditIcon,
  // CancelOutlined as CancelIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../contexts/StoreContext';
import AppDrawer from '../../../components/AppDrawer/AppDrawer';
import ProjectList from './ProjectList/ProjectList';

const ProjectDrawer = memo(() => {
  const classes = useStyles();
  const [
    edit,
    // NO MEGA-PROJECT (TEMPORARY)
    // setEdit
  ] = useState(true);
  const {
    profile,
    updateProfile,
    blocks: projects,
    addBlock: addProject,
    getBlock: getProject,
    // updateBlock: updateProject,
    BlockType,
    jsonToArray,
  } = useContext(StoreContext);

  // Parse projectIds
  const projectIds = jsonToArray(profile.projectIds);

  // Add orphan project ids to projectIds
  const orphanProjectIds = projects
    .filter((p) => p.type === BlockType.PROJECT && !projectIds.includes(p.id) && !p.parentBlockId)
    .map((p) => p.id);
  projectIds.push(...orphanProjectIds);

  return (
    <AppDrawer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="projects" type="project">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {/* List top of tree projects */}
              {projectIds.map((projectId, index) => {
                const project = getProject(projectId);

                // Skip if project not found (shouldn't happen)
                if (!project) return null;

                // Skip if it's not a project (error)
                if (project.type !== BlockType.PROJECT) return null;

                // Skip if has a parent block (that means it's a sub-project)
                if (project.parentBlockId) return null;

                return (
                  <ProjectList
                    key={projectId}
                    index={index}
                    projectId={projectId}
                    // NO MEGA-PROJECT (TEMPORARY)
                    // edit={edit}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {edit && (
          <List>
            <ListItem>
              <Button size="small" onClick={() => addProject({ type: BlockType.PROJECT })}>
                <AddIcon className={classes.leftIcon} /> Add a project
              </Button>
            </ListItem>
          </List>
        )}
      </DragDropContext>

      {/* NO MEGA-PROJECT (TEMPORARY) */}
      {/* <Fab color="secondary" className={classes.fab} size="small" onClick={() => setEdit(!edit)}>
        {(edit && <CancelIcon />) || <EditIcon />}
      </Fab> */}
    </AppDrawer>
  );

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    // Handle projects
    if (type === 'project') {
      const newProjectIds = Array.from(projectIds);
      newProjectIds.splice(source.index, 1);
      newProjectIds.splice(destination.index, 0, draggableId);

      updateProfile({ projectIds: JSON.stringify(newProjectIds) });
    }

    // Handle sub-projects
    if (type === 'subProject') {
      alert('TODO');
      // const sourceProject = projects[source.droppableId];
      // const destinationProject = projects[destination.droppableId];
      // const newSourceSubProjectIds = Array.from(sourceProject.blockIds);

      // // Remove sub-project from source project
      // newSourceSubProjectIds.splice(source.index, 1);

      // // If source and destination project are the same, add sub-project in the right spot
      // if (sourceProject === destinationProject)
      //   newSourceSubProjectIds.splice(destination.index, 0, draggableId);

      // const newSourceProject = {
      //   ...sourceProject,
      //   blockIds: newSourceSubProjectIds,
      // };

      // updateProject(newSourceProject);

      // // If destination is different from source, update it too
      // if (sourceProject !== destinationProject) {
      //   const newDestinationSubProjectIds = Array.from(destinationProject.blockIds);
      //   const subProject = projects[draggableId];

      //   // Add sub project in the right spot
      //   newDestinationSubProjectIds.splice(destination.index, 0, draggableId);

      //   const newDestinationProject = {
      //     ...destinationProject,
      //     blockIds: newDestinationSubProjectIds,
      //   };

      //   const newSubProject = {
      //     ...subProject,
      //     blockId: destinationProject.id,
      //   };

      //   updateProject(newDestinationProject);
      //   updateProject(newSubProject);
      // }
    }
  }
});

export default ProjectDrawer;
