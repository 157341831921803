import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// Material UI
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

const DroppableList = ({
  droppableId,
  droppableType,
  header,
  children,
  outerDragDropContext,
  onDragEnd,
}) => {
  let droppableList = (
    <Droppable droppableId={droppableId} type={droppableType}>
      {(provided) => (
        <List
          innerRef={provided.innerRef}
          {...provided.droppableProps}
          subheader={
            <ListSubheader>
              <Typography variant="overline">{header}</Typography>
            </ListSubheader>
          }
        >
          {children}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );

  if (!outerDragDropContext)
    droppableList = <DragDropContext onDragEnd={onDragEnd}>{droppableList}</DragDropContext>;

  return droppableList;
};

export default DroppableList;
