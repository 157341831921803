import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  // listItem: ({ divider }) =>
  //   !divider && {
  //     paddingTop: '0 !important',
  //     paddingBottom: '0 !important'
  //   },
  listItemSelected: {
    backgroundColor: theme.palette.primary.lightest + ' !important'
  },
  listItemText: {
    marginTop: '0 !important',
    marginBottom: '0 !important'
  },
  listItemSecondaryAction: {
    display: 'flex',
    alignItems: 'center'
  }
}));
