import React, { useContext } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { AppStateContext } from '../contexts/AppStateContext';
import { StoreContext } from '../contexts/StoreContext';
import CustomInput from './UI/CustomInput/CustomInputWithButtons';

export default function Profile() {
  const { email } = useContext(AppStateContext);
  const { loading, profile, updateProfile } = useContext(StoreContext);

  if (loading) return <h3>Loading...</h3>;

  return (
    <Card>
      <CardContent>
        <h2>Profile - {email}</h2>
        <div style={{ display: 'flex' }}>
          Firstname: &nbsp;
          <CustomInput
            value={profile?.firstname || ''}
            onChange={(value) => updateProfile({ firstname: value })}
            placeholder="First name"
          />
        </div>
        <div style={{ display: 'flex' }}>
          Lastname: &nbsp;
          <CustomInput
            value={profile?.lastname || ''}
            onChange={(value) => updateProfile({ lastname: value })}
            placeholder="Last name"
          />
        </div>
      </CardContent>
    </Card>
  );
}
