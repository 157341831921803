import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  header: {
    marginTop: '20px',
    marginBottom: '20px'
  }
}));

const Calendar = props => {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.toolbar} />
      <Typography variant="h5" className={classes.header}>
        Calendar
      </Typography>
      Coming soon.
    </Container>
  );
};

export default Calendar;
