import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  dragHandle: {
    top: '70%',
  },
  selectedSubHeader: {
    backgroundColor: theme.palette.primary.lightest + ' !important',
  },
  projectName: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
}));
