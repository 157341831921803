import React, { useState, useContext } from 'react';

// Lyfeplan
import { StoreContext } from '../../../contexts/StoreContext';
import DisplayedAction from './DisplayedAction/DisplayedAction';
import EditableAction from './EditableAction/EditableAction';
import { prettyDate } from '../../../utils/utils';

const Action = ({ actionId, create, onAddAction }) => {
  const { getAction } = useContext(StoreContext);
  const [editing, setEditing] = useState(create);
  const action = create ? {} : getAction(actionId);
  const [scheduledDate, setScheduledDate] = useState((action && action.scheduledDate) || null);

  if (!action) return null;

  const scheduledDateText = prettyDate(scheduledDate);

  return editing ? (
    <EditableAction
      action={action}
      scheduledDate={scheduledDate}
      setEditing={setEditing}
      setScheduledDate={setScheduledDate}
      onAddAction={onAddAction}
    />
  ) : (
    <DisplayedAction
      action={action}
      setEditing={setEditing}
      scheduledDateText={scheduledDateText}
    />
  );
};

export default Action;
