import React, { useContext } from 'react';

// Material UI
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../contexts/StoreContext';
import Goal from './Goal/Goal';
import CustomCard from '../../UI/CustomCard/CustomCard';
import DroppableList from '../../UI/DroppableList/DroppableList';
import DraggableListItem from '../../UI/DroppableList/DraggableListItem/DraggableListItem';

const GoalCard = ({ title, goalIds, planId, scope, topBarColor }) => {
  const classes = useStyles();
  const { addGoal } = useContext(StoreContext);

  return (
    <CustomCard
      title={title}
      topBarColor={topBarColor}
      noPadding
      footer={
        <Button size="small" onClick={() => addGoal(planId, { scope, status: 'toAchieve' })}>
          <AddIcon className={classes.leftIcon} /> Add Goal
        </Button>
      }
    >
      <DroppableList droppableId={`${planId}|${scope}`} droppableType="goal" outerDragDropContext>
        {goalIds?.map((goalId, index) => (
          <DraggableListItem key={goalId} draggableId={goalId} index={index}>
            <Goal goalId={goalId} />
          </DraggableListItem>
        ))}
      </DroppableList>
    </CustomCard>
  );
};

export default GoalCard;
