import React, { memo, useState, useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// Material UI
import { Fab, List, ListItem, Button } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, CancelOutlined as CancelIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../contexts/StoreContext';
import AppDrawer from '../../../components/AppDrawer/AppDrawer';
import AreaList from './AreaList/AreaList';

const GoalsDrawer = memo(() => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const {
    profile,
    updateProfile,
    addArea,
    getArea,
    updateArea,
    getCategory,
    updateCategory,
    jsonToArray,
  } = useContext(StoreContext);

  const areaIds = jsonToArray(profile.areaIds);

  return (
    <AppDrawer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="areas" type="area">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {/* List areas */}
              {areaIds.map((areaId, index) => (
                <AreaList key={areaId} index={index} areaId={areaId} edit={edit} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {edit && (
        <List>
          <ListItem>
            <Button size="small" onClick={() => addArea({ name: '' })}>
              <AddIcon className={classes.leftIcon} /> Add an area
            </Button>
          </ListItem>
        </List>
      )}

      <Fab color="secondary" className={classes.fab} size="small" onClick={() => setEdit(!edit)}>
        {(edit && <CancelIcon />) || <EditIcon />}
      </Fab>
    </AppDrawer>
  );

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    // Handle areas
    if (type === 'area') {
      const newAreaIds = Array.from(areaIds);
      newAreaIds.splice(source.index, 1);
      newAreaIds.splice(destination.index, 0, draggableId);

      updateProfile({ areaIds: JSON.stringify(newAreaIds) });
    }

    // Handle categories
    if (type === 'category') {
      const sourceArea = getArea(source.droppableId);
      const destinationArea = getArea(destination.droppableId);
      const newSourceCategoryIds = jsonToArray(sourceArea.categoryIds);

      // Remove category from source area
      newSourceCategoryIds.splice(source.index, 1);

      // If source and destination area are the same, add category in the right spot
      if (sourceArea === destinationArea) {
        newSourceCategoryIds.splice(destination.index, 0, draggableId);
        updateArea(sourceArea, { categoryIds: JSON.stringify(newSourceCategoryIds) });
      }

      // If destination is different from source, update it too
      if (sourceArea !== destinationArea) {
        const newDestinationCategoryIds = jsonToArray(destinationArea.categoryIds);
        const category = getCategory(draggableId);

        // Add category in the right spot
        newDestinationCategoryIds.splice(destination.index, 0, draggableId);

        updateArea(
          [sourceArea, destinationArea],
          [
            { categoryIds: JSON.stringify(newSourceCategoryIds) },
            { categoryIds: JSON.stringify(newDestinationCategoryIds) },
          ]
        );
        updateCategory(category, { areaId: destinationArea.id });
      }
    }
  }
});

export default GoalsDrawer;
