import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  progress: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
  h5: {
    marginTop: '20px',
    marginBottom: '20px'
  }
}));
