/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://psd54ru6r5hlbhwf6njoxax3ry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:07214c16-21ac-4eb2-a4ac-3007ac77535f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FALZnquWG",
    "aws_user_pools_web_client_id": "3ondcb8d8u8837a8hb5khpip4h",
    "oauth": {},
    "aws_user_files_s3_bucket": "vision-board-imagesprod-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
