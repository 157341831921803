import React, { useState, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, TextField } from '@material-ui/core';
import { DoneOutlined as DoneIcon, CancelOutlined as CancelIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  text: ({ empty }) => {
    const style = { whiteSpace: 'pre-wrap' };
    if (empty) style.color = theme.palette.grey[400];
    return style;
  },
  inputRoot: {
    paddingTop: 0,
  },
  inputInput: ({ inputStyle, variant }) => {
    if (inputStyle) {
      return inputStyle;
    } else {
      return theme.typography[variant || 'body1'];
    }
  },
}));

const CustomInput = ({
  inputStyle,
  variant,
  textClassName,
  placeholder,
  style,
  onChange,
  hidden,
  ...props
}) => {
  const classes = useStyles({ inputStyle, variant, empty: !props.value });
  const [value, setValue] = useState(props.value);
  const [valueCopy, setValueCopy] = useState(props.value);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Hidden
  if (hidden && !editing) return null;

  // Editing
  if (editing) {
    return (
      <>
        <TextField
          type="textarea"
          autoFocus
          multiline
          fullWidth
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputInput,
            },
          }}
          placeholder={placeholder}
          value={value || ''}
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(event) => {
            // console.log(`Pressed charCode ${event.charCode}`);
            // console.log(`Pressed key ${event.key}`);
            // console.log(event);
            // if ((event.ctrlKey || event.shiftKey) && event.charCode === 13) {
            if ((event.ctrlKey || event.shiftKey) && event.key === 'Enter') {
              return;
            }
            if (event.key === 'Enter') {
              event.preventDefault();
              setEditing(false);
              onChange(value);
            }
            if (event.key === 'Escape') {
              event.preventDefault();
              setEditing(false);
              setValue(valueCopy);
            }
          }}
        />

        <Tooltip title="Apply (Enter)" placement="top">
          <IconButton
            onClick={() => {
              setEditing(false);
              onChange(value);
            }}
          >
            <DoneIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Cancel (Esc)" placement="top">
          <IconButton
            onClick={() => {
              setEditing(false);
              setValue(valueCopy);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  // Displaying
  return (
    <Typography
      variant={variant || 'body1'}
      className={classes.text + ' ' + textClassName}
      onClick={() => {
        setEditing(true);
        setValueCopy(value);
      }}
      style={style}
    >
      {value || placeholder || ' '}
    </Typography>
  );
};

export default CustomInput;
