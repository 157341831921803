import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

// Lyfeplan
import useStyles from './styles';

const CustomCard = ({
  title,
  options,
  header,
  children,
  footer,
  minimized,
  topBarColor,
  noPadding,
}) => {
  const classes = useStyles({ topBarColor, noPadding });

  const cardHeaderTitle = (
    <div className={classes.cardHeaderTitle}>
      <div style={{ flexGrow: 1, display: 'flex' }}>
        <Typography variant="overline">{title || ''}</Typography>
      </div>
      {options}
    </div>
  );

  return (
    <Card>
      <CardHeader className={classes.topBar} title={cardHeaderTitle} />

      {header && <CardContent>{header}</CardContent>}

      <CardContent classes={{ root: classes.padding }}>{children}</CardContent>

      {footer && !minimized && (
        <CardContent classes={{ root: classes.footer }}>
          <Typography variant="body1">{footer}</Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default CustomCard;
