import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

// Material UI
import { Paper, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core';
import { DragHandle as DragHandleIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';

const DraggableListItem = ({ draggableId, index, children, isDragDisabled }) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <Paper
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          square={!snapshot.isDragging}
          elevation={snapshot.isDragging ? 4 : 0}
        >
          <ListItem classes={{ root: classes.listItem, selected: classes.listItemSelected }}>
            <ListItemText className={classes.listItemText} primary={React.cloneElement(children)} />
            {!isDragDisabled && (
              <ListItemSecondaryAction
                {...provided.dragHandleProps}
                className={classes.listItemSecondaryAction}
              >
                <DragHandleIcon color="disabled" />
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <Divider />
        </Paper>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
