import React, { memo, useState, useContext } from 'react';

// Material UI
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Collapse,
} from '@material-ui/core';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../../contexts/StoreContext';

const Filters = memo(() => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [opened, setOpened] = useState([]);
  const { profile, getArea, getCategory, jsonToArray } = useContext(StoreContext);

  function onAreaCollapseClick(areaId) {
    const currentIndex = opened.indexOf(areaId);
    const newOpened = [...opened];

    if (currentIndex === -1) {
      newOpened.push(areaId);
    } else {
      newOpened.splice(currentIndex, 1);
    }

    setOpened(newOpened);
  }

  function onAreaCheckboxClick(areaId) {
    const { areaChecked } = getAreaCheckboxStatus(areaId);

    if (!areaChecked) {
      // Check all categories
      const newChecked = [...checked];
      newChecked.push(...jsonToArray(getArea(areaId).categoryIds));
      setChecked(Array.from(new Set(newChecked)));
    } else {
      // Uncheck all categories
      const newChecked = [...checked].filter(
        (categoryId) => !jsonToArray(getArea(areaId).categoryIds).includes(categoryId)
      );
      setChecked(newChecked);
    }
  }

  function getAreaCheckboxStatus(areaId) {
    const categoriesCount = jsonToArray(getArea(areaId).categoryIds).length;
    const checkedCategoriesCount = jsonToArray(getArea(areaId).categoryIds).filter((categoryId) =>
      checked.includes(categoryId)
    ).length;
    return {
      areaChecked: checkedCategoriesCount === categoriesCount,
      areaIndeterminate: checkedCategoriesCount !== categoriesCount && checkedCategoriesCount > 0,
    };
  }

  function onCategoryCheckboxClick(categoryId) {
    const currentIndex = checked.indexOf(categoryId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(categoryId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const areaIds = jsonToArray(profile.areaIds);

  return (
    <List
      dense
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="overline">Filters</Typography>
        </ListSubheader>
      }
    >
      {/* List areas */}
      {areaIds.map((areaId) => {
        const area = getArea(areaId);
        if (area) {
          const areaCategories = jsonToArray(area.categoryIds).map((categoryId) =>
            getCategory(categoryId)
          );
          const { areaChecked, areaIndeterminate } = getAreaCheckboxStatus(areaId);

          return (
            <React.Fragment key={area.id}>
              <ListItem button>
                <ListItemIcon onClick={() => onAreaCheckboxClick(area.id)}>
                  <Checkbox
                    edge="start"
                    checked={areaChecked}
                    indeterminate={areaIndeterminate}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={area.name || 'New area'}
                  onClick={() => onAreaCheckboxClick(area.id)}
                />
                {opened.includes(area.id) ? (
                  <ExpandLessIcon
                    onClick={() => {
                      onAreaCollapseClick(area.id);
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    onClick={() => {
                      onAreaCollapseClick(area.id);
                    }}
                  />
                )}
              </ListItem>

              <Collapse in={opened.includes(area.id)} timeout="auto" unmountOnExit>
                <List dense>
                  {areaCategories.map((category) => (
                    <ListItem
                      key={category.id}
                      className={classes.nested}
                      button
                      onClick={() => onCategoryCheckboxClick(category.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.includes(category.id)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={category.name || 'New category'} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}
    </List>
  );
});

export default Filters;
