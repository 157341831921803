import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
  h5: {
    marginTop: '20px',
    marginBottom: '20px'
  }
}));
