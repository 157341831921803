import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // displayedAction: {
  //   display: 'flex',
  //   alignItems: 'flex-start'
  // },
  editableAction: {
    marginTop: theme.spacing(1.5)
  },
  // editableTextfield: {
  //   paddingTop: theme.spacing(1.5),
  //   paddingRight: theme.spacing(1.5),
  //   paddingLeft: theme.spacing(1.5)
  // },
  // strike: {
  //   textDecoration: 'line-through',
  //   color: theme.palette.grey[500]
  // },
  // empty: {
  //   color: theme.palette.grey[500]
  // },
  inputRoot: {
    paddingTop: 0,
  },
  inputInput: theme.typography['body1'],
  // duration: {
  //   fontSize: '.9rem',
  //   color: theme.palette.grey[500]
  // },
  // customDatePicker: {
  //   visibility: 'none',
  //   width: 0
  // }
}));
