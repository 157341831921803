import React, { memo, useState, useContext } from 'react';
import moment from 'moment';

// Material UI
import {
  Button,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Lyfeplan
import { AppStateContext } from '../../contexts/AppStateContext';
import { StoreContext } from '../../contexts/StoreContext';
import Plan from '../Plan/Plan';

const useStyles = makeStyles((theme) => ({
  tabRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    flexGrow: 1,
    width: '100%',
  },
  addButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AddYearButton = ({ categoryId }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [year, setYear] = useState('2020');
  const { addPlan } = useContext(StoreContext);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button size="small" onClick={() => setOpen(true)} className={classes.addButton}>
        <AddIcon className={classes.leftIcon} /> Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Add Year Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the year you want to create a plan for.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="year"
            label="Year"
            type="number"
            fullWidth
            placeholder="YYYY"
            value={year}
            onChange={(event) => setYear(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              addPlan(categoryId, { year: parseInt(year) });
              handleClose();
            }}
            color="primary"
          >
            <AddIcon /> Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && children}
    </div>
  );
};

const PlanList = memo(({ categoryId }) => {
  const classes = useStyles();
  const { selectedPlanId, updateAppState } = useContext(AppStateContext);
  const { getCategory, getPlan, jsonToArray } = useContext(StoreContext);

  const category = getCategory(categoryId);
  if (!category) return null;

  // Sort plans by year
  const planIds = jsonToArray(category.planIds).sort(
    (a, b) => (getPlan(b)?.year || 0) - (getPlan(a)?.year || 0)
  );

  // Select tab
  let tabIndex = planIds.findIndex((id) => id === selectedPlanId);
  if (tabIndex < 0)
    tabIndex = planIds.findIndex((id) => getPlan(id)?.year === parseInt(moment().format('YYYY')));
  if (tabIndex < 0) tabIndex = 0;

  return (
    <>
      <div className={classes.tabRow}>
        <Tabs
          className={classes.tabs}
          value={tabIndex}
          onChange={(event, index) => updateAppState({ selectedPlanId: planIds[index] })}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {planIds.map((planId, index) => (
            <Tab label={getPlan(planId)?.year} id={`tab-${index}`} key={planId} />
          ))}
        </Tabs>
        <AddYearButton categoryId={categoryId} />
      </div>

      {planIds.map((planId, index) => (
        <TabPanel value={tabIndex} index={index} key={planId}>
          <Plan planId={planId} selected={true} />
        </TabPanel>
      ))}
    </>
  );
});

export default PlanList;
