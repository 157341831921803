import React, { memo } from 'react';

// Lyfeplan
import AppDrawer from '../../../components/AppDrawer/AppDrawer';
import TimeScope from './TimeScope/TimeScope';
import Options from './Options/Options';
import Filters from './Filters/Filters';

const ActionsDrawer = memo(() => {
  return (
    <AppDrawer>
      <TimeScope />
      <Options />
      <Filters />
    </AppDrawer>
  );
});

export default ActionsDrawer;
