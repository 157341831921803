import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  topBar: ({ topBarColor }) => ({
    backgroundColor:
      topBarColor === 'grey'
        ? theme.palette.grey[500]
        : topBarColor === 'tertiary'
        ? theme.palette.tertiary.main
        : topBarColor === 'secondary'
        ? theme.palette.secondary.main
        : topBarColor === 'primary' || topBarColor === undefined
        ? theme.palette.primary.main
        : topBarColor,
    color: theme.palette.primary.contrastText,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  cardHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    '& >:last-child': {
      marginRight: theme.spacing(-1.5),
    },
  },
  padding: ({ noPadding }) =>
    noPadding
      ? {
          padding: 0,
          '&:last-child': {
            padding: 0,
          },
        }
      : {},
  footer: {
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
}));
