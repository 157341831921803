import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  // Contains: .inputs, .statusAndMenu
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      marginRight: 5
    },
    minHeight: 36
  },

  // Contains: .description, .note
  inputs: ({ isTabletOrMobile }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  }),
  description: ({ isTabletOrMobile }) => ({}),
  note: ({ isTabletOrMobile }) => ({}),

  // Contains: .status, .menuButton
  statusAndMenu: ({ isTabletOrMobile }) => ({
    display: 'flex',
    alignItems: 'center'
  }),
  status: ({ isTabletOrMobile }) => ({}),
  menuButton: { marginTop: -6, marginBottom: -6 }
}));
