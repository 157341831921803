import React, { useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import detectIt from 'detect-it';

// Material UI
import { IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../../contexts/StoreContext';
import useMenu from '../../../../hooks/useMenu';
import CustomInput from '../../../UI/CustomInput/CustomInputWithButtons';
import StatusChip from '../../../UI/StatusChip/StatusChip';
import { STATUSES } from '../../../../settings';

const Goal = ({ goalId }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const classes = useStyles({ isTabletOrMobile });
  const [hover, setHover] = useState(false);
  const {
    getGoal,
    updateGoal,
    deleteGoal,
    // addBlock
  } = useContext(StoreContext);

  const { menu, onMenuClick } = useMenu([
    // TODO
    // {
    //   label: 'Create a related Result Block',
    //   onClick: () => addBlock({ type: BlockType.RESULT, goalIds: [goalId] }),
    // },
    { label: 'Delete', onClick: () => deleteGoal(goalId) },
  ]);

  const { menu: statusMenu, onMenuClick: onStatusMenuClick } = useMenu(
    Object.entries(STATUSES).map(([status, statusProps]) => ({
      label: statusProps.label,
      onClick: () => updateGoal(goal, { status }),
    }))
  );

  const goal = getGoal(goalId);
  if (!goal) return null;

  const { description, status, note } = goal;
  const touchOnly = detectIt.deviceType === 'touchOnly';

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* Inputs */}
      <div className={classes.inputs}>
        {/* Description */}
        <div className={classes.description}>
          <CustomInput
            value={description}
            placeholder="New goal"
            onChange={(value) => updateGoal(goal, { description: value })}
          />
        </div>

        {/* Note */}
        <div className={classes.note}>
          <CustomInput
            value={note}
            placeholder="Note"
            onChange={(value) => updateGoal(goal, { note: value })}
            hidden={!touchOnly && !hover && !note}
            style={{ color: STATUSES[status || 'toAchieve'].color }}
          />
        </div>
      </div>

      {/* Status and menu button */}
      <div className={classes.statusAndMenu}>
        {/* Status */}
        <StatusChip value={status} onClick={onStatusMenuClick} />
        {statusMenu}

        {/* Menu button */}
        <IconButton onClick={onMenuClick} className={classes.menuButton}>
          <MoreVertIcon color="disabled" />
        </IconButton>
        {menu}
      </div>
    </div>
  );
};

export default Goal;
