import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import createPersistedState from 'use-persisted-state';
import moment from 'moment';

// Lyfeplan
import useInterval from '../hooks/useInterval';

const defaultAppState = {
  drawerOpen: false,
  minimizeBlocks: false,
  showCompleted: false,
  showCompletedExceptions: [],
  selectedCategoryId: null,
  selectedDate: null,
  selectedPlanId: null,
  selectedProjectId: null,
  timeScope: null,
  today: true,
};

const usePersistedState = createPersistedState('appState');

export const AppStateContext = createContext();

const AppStateContextProvider = (props) => {
  const [appState, setAppState] = usePersistedState(defaultAppState);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    const getUserInfo = async () => {
      const data = await Auth.currentAuthenticatedUser({ bypassCache: false });
      setUser(data);
      setLoggedIn(!!data);
      setEmail(data?.attributes?.email);
    };
    getUserInfo();
  }, []);

  useInterval(() => {
    // Update selectedDate when day change
    if (appState.today && !moment().isSame(appState.selectedDate, 'day')) {
      setAppState({ ...appState, selectedDate: moment().startOf('day').format() });
    }
  }, 1000);

  const updateAppState = (props = {}) => {
    // Filter out props that haven't been defined above (in usePersistedState)
    Object.keys(props)
      .filter((key) => !(key in defaultAppState))
      .forEach((key) => delete props[key]);

    setAppState({ ...appState, ...props });
  };

  if (!user || !loggedIn || !email) return null;

  return (
    <AppStateContext.Provider value={{ updateAppState, loggedIn, user, email, ...appState }}>
      {props.children}
    </AppStateContext.Provider>
  );
};

export default AppStateContextProvider;
