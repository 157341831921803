import React from 'react';

// Material UI
import { Typography } from '@material-ui/core';

// Lyfeplan
import { APP_VERSION } from '../../settings';

const Logo = () => {
  return (
    <Typography variant="h6">
      Lyfeplan <span style={{ fontSize: '0.5em' }}>{APP_VERSION}</span>
    </Typography>
  );
};

export default Logo;
