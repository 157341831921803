import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  customDatePicker: {
    color: theme.palette.secondary.contrastText,
    '&::before': {
      border: '0 !important',
    },
    '&::after': {
      border: '0 !important',
    },
    visibility: 'none',
    width: 0,
  },
}));
