import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Material UI
import { Tabs, Tab } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';

const LoggedInMenu = ({ isTabletOrMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const tabValue = location.pathname.substr(location.pathname.indexOf('/') + 1);
  // TODO: import AWS logout function
  const userLogout = () => {};

  if (!tabValue) return null;

  return (
    <>
      <div className={classes.grow} />
      <Tabs
        value={!['login', 'signup'].includes(tabValue) ? tabValue : 'dashboard'}
        varia-label="Content"
        className={classes.tabs}
        classes={{ indicator: classes.tabsIndicator }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label="Dashboard"
          value="dashboard"
          component={Link}
          to="dashboard"
          className={classes.tab}
        />
        <Tab label="Goals" value="goals" component={Link} to="goals" className={classes.tab} />
        <Tab
          label="Projects"
          value="projects"
          component={Link}
          to="projects"
          className={classes.tab}
        />
        <Tab
          label="Actions"
          value="actions"
          component={Link}
          to="actions"
          className={classes.tab}
        />
        <Tab
          label="Calendar"
          value="calendar"
          component={Link}
          to="calendar"
          className={classes.tab}
        />
        <Tab
          label="Reports"
          value="reports"
          component={Link}
          to="reports"
          className={classes.tab}
        />
      </Tabs>

      {!isTabletOrMobile && (
        <>
          <div className={classes.grow} />
          <ProfileMenu userLogout={userLogout} />
        </>
      )}
    </>
  );
};

export default LoggedInMenu;
