import React, { memo, useState, useContext } from 'react';

// Material UI
import { Grid, Button, IconButton } from '@material-ui/core';
import { Add as AddIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../contexts/StoreContext';
import useMenu from '../../hooks/useMenu';
import CustomInput from '../UI/CustomInput/CustomInputWithButtons';
import CustomCard from '../UI/CustomCard/CustomCard';
import BlockList from '../BlockList/BlockList';
import BlockDatePicker from '../Block/BlockDatePicker/BlockDatePicker';

const Project = memo(({ projectId }) => {
  const classes = useStyles();
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const {
    getBlock: getProject,
    updateBlock: updateProject,
    deleteBlock: deleteProject,
    addBlock,
    BlockType,
  } = useContext(StoreContext);

  const { menu, onMenuClick } = useMenu([
    // { label: 'Schedule', onClick: () => {} },
    // { label: 'Hide completed', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteProject(projectId) },
  ]);

  let project = getProject(projectId);
  if (!project) return null;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Project name */}
        <div className={classes.projectName}>
          <CustomInput
            variant="h5"
            value={project.name}
            placeholder="New project"
            onChange={(value) => updateProject(project, { name: value })}
          />
        </div>

        {/* Date picker */}
        <BlockDatePicker
          blockId={projectId}
          block={project}
          showDate={true}
          isOpenSchedule={isOpenSchedule}
          setIsOpenSchedule={setIsOpenSchedule}
          updateBlock={updateProject}
        />

        {/* Menu button */}
        <IconButton color="inherit" onClick={onMenuClick}>
          <MoreVertIcon />
        </IconButton>
        {menu}
      </div>

      {/* Date */}
      {/* <div>Date Range</div> */}

      {/* Categories */}
      {/* <div>Linked Categories: {project.categoryIds && project.categoryIds.join(', ')}</div> */}

      {/* Goals */}
      {/* <div>Linked Goals: {project.goalIds && project.goalIds.join(', ')}</div> */}

      {/* Roles */}
      {/* <div>Linked Roles</div> */}

      {/* Project description */}
      <Grid container spacing={3} style={{ marginTop: 12, marginBottom: 12 }}>
        <Grid item xs={12} md={6}>
          <CustomCard title="Ultimate Result">
            <CustomInput
              value={project.result}
              placeholder={'Describe your ultimate result (the "what")...'}
              onChange={(value) => updateProject(project, { result: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="Ultimate Purpose">
            <CustomInput
              value={project.purpose}
              placeholder={'Describe your ultimate purpose (the "why")...'}
              onChange={(value) => updateProject(project, { purpose: value })}
            />
          </CustomCard>
        </Grid>
      </Grid>

      {/* Result Blocks */}
      <BlockList blockId={projectId} dragEnabled />

      {/* Add a result button */}
      <div style={{ padding: 20 }}>
        <Button
          size="small"
          onClick={() => addBlock({ type: BlockType.RESULT, parentBlockId: projectId })}
        >
          <AddIcon className={classes.leftIcon} /> Add a result
        </Button>
      </div>
    </>
  );
});

export default Project;
