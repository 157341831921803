import React from 'react';

// Material UI
import { Container, Typography, Grid } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import Profile from '../../components/Profile';

const Dashboard = () => {
  const classes = useStyles();

  // Define current scope
  // const scope = 'q1'; // TODO: auto-detect based on current date

  return (
    <>
      <Container>
        <div className={classes.toolbar} />

        <Typography variant="h5" className={classes.header}>
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Profile />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
