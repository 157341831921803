import React, { useState, useContext } from 'react';
import moment from 'moment';

// Material UI
import { Typography, Divider, Button, IconButton, Tooltip, Checkbox } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { StoreContext } from '../../../contexts/StoreContext';
import useMenu from '../../../hooks/useMenu';
import { prettyDuration } from '../../../utils/utils';
import CustomCard from '../../UI/CustomCard/NewCustomCard';
import CustomInput from '../../UI/CustomInput/CustomInputWithButtons';
import CustomDatePicker from '../../UI/CustomDatePicker/CustomDatePicker';
import DroppableList from '../../UI/DroppableList/DroppableList';
import DraggableListItem from '../../UI/DroppableList/DraggableListItem/DraggableListItem';
import Action from '../Action/Action';
// import GoalsPicker from '../../GoalsPicker/GoalsPicker';

const ResultBlock = ({ blockId, dragHandleProps, showDate }) => {
  const classes = useStyles();
  const [showAddActionInput, setShowAddActionInput] = useState(false);
  const { minimizeBlocks, showCompleted, showCompletedExceptions, updateAppState } = useContext(
    AppStateContext
  );
  const { getBlock, addAction, getAction, updateBlock, deleteBlock, jsonToArray } = useContext(
    StoreContext
  );

  const { menu, onMenuClick } = useMenu([
    { label: 'Schedule', onClick: () => {} },
    { label: 'Toggle completed', onClick: () => onBlockToggleCompleted(blockId) },
    { label: 'Hide completed', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteBlock(blockId) },
  ]);

  const block = getBlock(blockId);
  if (!block) return null;

  const onBlockToggleCompleted = () => {
    updateBlock(block, { completedDate: block.completedDate ? null : moment().format() });
  };

  const onAddAction = (action) => {
    if (action) {
      addAction({ blockId, ...action });
    } else {
      setShowAddActionInput(false);
    }
  };

  const onToggleBlockShowCompleted = () =>
    updateAppState({
      showCompletedExceptions: !Array.isArray(showCompletedExceptions)
        ? [blockId]
        : !showCompletedExceptions.includes(blockId)
        ? [...showCompletedExceptions, blockId]
        : showCompletedExceptions.filter((e) => e !== blockId),
    });

  const actionIds = jsonToArray(block.actionIds);
  const blockShowCompleted =
    (showCompleted === true && !showCompletedExceptions?.includes?.(blockId)) ||
    (showCompleted === false && showCompletedExceptions?.includes?.(blockId));
  const completedActionIds = blockShowCompleted ? jsonToArray(block.completedActionIds) : [];
  const estimatedTime = actionIds
    .map((id) => getAction(id)?.duration || 0)
    .reduce((total, time) => total + time, 0);

  // Debug
  // console.log({ block: block.result, actionIds });
  // console.log('ResultBlock:', block.result);

  return (
    <CustomCard
      topBarColor="secondary"
      title={
        <>
          <WhiteCheckbox
            edge="start"
            onClick={() => onBlockToggleCompleted(blockId)}
            checked={!!block.completedDate}
          />
          {'Result'}
        </>
      }
      options={
        <>
          {/* Date picker */}
          <CustomDatePicker
            date={block.scheduledDate}
            showDate={showDate}
            onChange={(momentDate) =>
              updateBlock(block, { scheduledDate: momentDate ? momentDate.format() : null })
            }
          />

          {/* Show completed option */}
          <Tooltip title={blockShowCompleted ? 'Hide completed' : 'Show completed'} placement="top">
            <IconButton color="inherit" onClick={onToggleBlockShowCompleted}>
              {blockShowCompleted ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Tooltip>

          {/* Menu button */}
          <IconButton color="inherit" onClick={onMenuClick}>
            <MoreVertIcon />
          </IconButton>
          {menu}

          {/* Drag handle */}
          {dragHandleProps && (
            <div {...dragHandleProps} className={classes.dragHandleIcon}>
              <DragHandleIcon style={{ display: 'block' }} />
            </div>
          )}
        </>
      }
      noPadding
      minimized={minimizeBlocks}
    >
      <div className={classes.cardPadding}>
        {/* Goals */}
        {/* <div style={{ marginBottom: 8 }}>
          <GoalsPicker />
        </div> */}

        {/* Result */}
        <Typography variant="body1" component="div">
          <CustomInput
            value={block.result}
            placeholder="Result (what)"
            onChange={(value) => updateBlock(block, { result: value })}
          />
        </Typography>

        {/* Purpose */}
        {!minimizeBlocks && (
          <Typography variant="body1" component="div" className={classes.purpose}>
            <CustomInput
              value={block.purpose}
              placeholder="Purpose (why)"
              onChange={(value) => updateBlock(block, { purpose: value })}
            />
          </Typography>
        )}
      </div>

      {!minimizeBlocks && (
        <>
          <Divider />

          {/* Action items */}
          <div>
            <DroppableList droppableId={blockId} droppableType="action" outerDragDropContext>
              {actionIds.map((actionId, index) => (
                <DraggableListItem key={actionId} draggableId={actionId} index={index}>
                  <Action actionId={actionId} />
                </DraggableListItem>
              ))}
            </DroppableList>
          </div>

          {/* Completed Action items */}
          <DroppableList
            droppableId={blockId + '|completed'}
            droppableType="completedAction"
            outerDragDropContext
          >
            {completedActionIds.map((actionId, index) => (
              <DraggableListItem key={actionId} draggableId={actionId} index={index}>
                <Action actionId={actionId} />
              </DraggableListItem>
            ))}
          </DroppableList>
        </>
      )}

      {/* Add action input */}
      {showAddActionInput && (
        <div style={{ padding: '8px 16px' }}>
          <Action create onAddAction={onAddAction} />
        </div>
      )}

      <div className={classes.cardBottom}>
        {/* Add action button */}
        <Button size="small" onClick={() => setShowAddActionInput(true)}>
          <AddIcon className={classes.leftIcon} /> Add Action
        </Button>

        {/* Total */}
        <div className={classes.totals}>
          <div>Total: {prettyDuration(estimatedTime)}</div>
          {/* <div className={classes.must}>Must: {prettyDuration(estimatedTime)}</div> */}
        </div>
      </div>
    </CustomCard>
  );
};

const WhiteCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    '&$checked': {
      color: theme.palette.primary.contrastText,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default ResultBlock;
