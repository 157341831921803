import moment from 'moment';

const prettyDate = (date, empty = '') => {
  return moment(date).isValid()
    ? moment(date).format('YYYY') === moment().format('YYYY')
      ? moment(date).format('MMM Do')
      : moment(date).format('MMM Do, YYYY')
    : empty;
};

const prettyDuration = (minutes) => {
  const d = moment.duration(minutes, 'm');
  return minutes < 60
    ? `${d.minutes()}m`
    : `${d.hours()}h${d.minutes().toString().padStart(2, '0')}`;
};

export { prettyDate, prettyDuration };
