import React, { useState } from 'react';
import moment from 'moment';

// Material UI
import { Menu, MenuItem, Typography, IconButton, Button } from '@material-ui/core';
import { Timer as DurationIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { prettyDuration } from '../../../utils/utils';

const durations = [
  moment.duration(5, 'minutes'),
  moment.duration(10, 'minutes'),
  moment.duration(15, 'minutes'),
  moment.duration(30, 'minutes'),
  moment.duration(45, 'minutes'),
  moment.duration(1, 'hour'),
  moment.duration(1.5, 'hours'),
  moment.duration(2, 'hours'),
  moment.duration(2.5, 'hours'),
  moment.duration(3, 'hours'),
  moment.duration(4, 'hours'),
  moment.duration(5, 'hours'),
  moment.duration(6, 'hours'),
  moment.duration(7, 'hours'),
  moment.duration(8, 'hours'),
];

const DurationPicker = ({ duration, showDuration, onChange, buttonContent, color, size }) => {
  const classes = useStyles();
  const [tempDuration, setTempDuration] = useState(duration);

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const selectDuration = (momentDuration) => {
    onChange && onChange(momentDuration);
    setTempDuration(momentDuration?.asMinutes());
    closeMenu();
  };

  return (
    <div className={classes.root}>
      {/* Duration */}
      {showDuration && (
        <Typography variant="body1" onClick={openMenu} color={color || 'inherit'}>
          {prettyDuration(tempDuration)}
        </Typography>
      )}

      {/* Button */}
      {buttonContent ? (
        <Button onClick={openMenu} color={color || 'inherit'} size={size || 'medium'}>
          {buttonContent}
        </Button>
      ) : (
        <IconButton onClick={openMenu} color={color || 'inherit'}>
          <DurationIcon />
        </IconButton>
      )}

      {/* Menu */}
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem onClick={() => selectDuration(null)}>No duration</MenuItem>
        {durations.map((d, index) => (
          <MenuItem key={index} onClick={() => selectDuration(d)}>
            {prettyDuration(d.asMinutes())}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DurationPicker;
