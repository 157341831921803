import React, { memo, useState, useContext } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// Material UI
import {
  List,
  ListItem,
  ListSubheader,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../../contexts/AppStateContext';
import { StoreContext } from '../../../../contexts/StoreContext';
import useMenu from '../../../../hooks/useMenu';
import ProjectListItem from './ProjectListItem/ProjectListItem';
// import CustomInput from '../../../../components/UI/CustomInput/CustomInputWithButtons';

const ProjectList = memo(({ index, projectId, edit }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const { selectedProjectId, updateAppState } = useContext(AppStateContext);
  const {
    addBlock: addProject,
    getBlock: getProject,
    // updateBlock: updateProject,
    deleteBlock: deleteProject,
    BlockType,
    jsonToArray,
  } = useContext(StoreContext);

  const { menu, isMenuOpen, onMenuClick } = useMenu([
    // { label: 'Rename', onClick: () => {} },
    // { label: 'Share', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteProject(projectId) },
  ]);

  const project = getProject(projectId);
  if (!project) return null;

  const blockIds = jsonToArray(project.blockIds);

  return (
    <>
      <Draggable draggableId={projectId} index={index}>
        {(projectProvided, snapshot) => (
          <Paper
            {...projectProvided.draggableProps}
            innerRef={projectProvided.innerRef}
            square={!snapshot.isDragging}
            elevation={snapshot.isDragging ? 4 : 0}
          >
            <Droppable droppableId={projectId} type="subProject">
              {(provided) => (
                <List
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                  dense
                  style={{ paddingBottom: 0 }}
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      style={{ display: 'flex', paddingRight: 48 }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={() => updateAppState({ selectedProjectId: projectId })}
                      classes={
                        (selectedProjectId === projectId && { root: classes.selectedSubHeader }) ||
                        null
                      }
                    >
                      {/* Project name */}
                      <div style={{ flexGrow: 1 }}>
                        {/* <CustomInput
                          variant="inherit"
                          inputStyle={{ fontSize: '0.875rem', marginTop: 12 }}
                          value={project.name}
                          placeholder="New project"
                          onChange={(value) => updateProject(project, { name: value })}
                        /> */}
                        <Typography variant="body2" className={classes.projectName}>
                          {project.name || 'New project'}
                        </Typography>
                      </div>

                      {/* Project menu button */}
                      {(edit || hover || isMenuOpen) && (
                        <IconButton onClick={onMenuClick}>
                          <MoreVertIcon fontSize="small" color="disabled" />
                        </IconButton>
                      )}

                      {/* Project drag handle */}
                      <ListItemSecondaryAction
                        className={classes.dragHandle}
                        {...projectProvided.dragHandleProps}
                      >
                        {(edit || hover || isMenuOpen) && <DragHandleIcon color="disabled" />}
                      </ListItemSecondaryAction>
                    </ListSubheader>
                  }
                >
                  {/* Loop sub-projects */}
                  {blockIds.map((subProjectId, index) => {
                    if (getProject(subProjectId)?.type !== BlockType.PROJECT) return null;
                    return (
                      <ProjectListItem
                        key={subProjectId}
                        projectId={subProjectId}
                        index={index}
                        edit={edit}
                      />
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>

            {edit && (
              <List>
                <ListItem>
                  <Button
                    size="small"
                    onClick={() => addProject({ type: BlockType.PROJECT, blockId: projectId })}
                  >
                    <AddIcon className={classes.leftIcon} /> Add a sub-project
                  </Button>
                </ListItem>
              </List>
            )}

            <Divider />
          </Paper>
        )}
      </Draggable>

      {menu}
    </>
  );
});

export default ProjectList;
