import React, { useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';

// Material UI
import { Container, Typography, Grid } from '@material-ui/core';
import { InsertInvitation as CalendarIcon, Add as AddIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import useDropAction from '../../hooks/useDropAction';
import { AppStateContext } from '../../contexts/AppStateContext';
import { StoreContext } from '../../contexts/StoreContext';
import ActionsDrawer from './ActionsDrawer/ActionsDrawer';
import BlockList from '../../components/BlockList/BlockList';
import CustomDatePicker from '../../components/UI/CustomDatePicker/CustomDatePicker';

const Actions = () => {
  const classes = useStyles();
  const { timeScope, selectedDate } = useContext(AppStateContext);
  const { blocks, addBlock, getBlock, updateBlock, BlockType } = useContext(StoreContext);
  const dropAction = useDropAction();

  // Sort and split blocks by time scope
  const timeScopes = {};
  const blockIdsByTimeScope = sortAndSplitBlocks(blocks);

  return (
    <>
      <ActionsDrawer />

      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={classes.toolbar} />

          {/* Loop time scopes */}
          {blockIdsByTimeScope.map((blockIds, index) => {
            const firstBlockInfo = timeScopes[blockIds[0]];
            if (!firstBlockInfo) return null;
            return (
              <div key={`scope-${index}`}>
                {/* Header */}
                <Typography variant="h5" className={classes.h5}>
                  {firstBlockInfo.overdue ? (
                    <div style={{ display: 'flex' }}>
                      <span className={classes.overdue}>{firstBlockInfo.timeScopeTitle}</span>
                      {/* Reschedule button */}
                      <CustomDatePicker
                        onChange={rescheduleOverdue}
                        // color="primary"
                        buttonContent={
                          <>
                            <CalendarIcon className={classes.leftIcon} />
                            Reschedule overdue
                          </>
                        }
                      />
                    </div>
                  ) : (
                    firstBlockInfo.timeScopeTitle
                  )}
                  {firstBlockInfo.timeScopeFormattedDate && ' '}
                  <span className={classes.timeScopeFormattedDate}>
                    {firstBlockInfo.timeScopeFormattedDate}
                  </span>
                </Typography>

                {/* Droppable for blocks */}
                <Droppable droppableId="blocks" type="rootBlock">
                  {(provided) => (
                    <Grid
                      container
                      spacing={3}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <BlockList blockIds={blockIds} />
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
                {/* /Droppable for blocks */}
              </div>
            );
          })}
          {/* /Loop time scopes */}
        </DragDropContext>

        {/* Add Result button */}
        <div style={{ padding: 20 }}>
          <CustomDatePicker
            onChange={(momentDate) =>
              addBlock({ type: BlockType.RESULT, scheduledDate: momentDate.format() })
            }
            buttonContent={
              <>
                <AddIcon className={classes.leftIcon} /> Add a Result
              </>
            }
            size="small"
          />
        </div>
      </Container>
    </>
  );

  // Sort and split blocks by time scope
  function sortAndSplitBlocks(blocks) {
    // Define date range lengths
    const ranges = {
      day: 0,
      week: 6,
      month: moment(selectedDate).daysInMonth() - 1,
    };
    const start = moment(selectedDate).startOf('day');
    const end = moment(start).add(ranges[timeScope], 'days').endOf('day');

    // Filter and sort blocks
    let filteredAndSortedBlocks = blocks
      // Filter selected date range or overdue
      .filter(
        (block) =>
          // Selected range
          moment(block.scheduledDate).isBetween(start, end, undefined, '[]') ||
          // Overdue
          (moment(block.scheduledDate || 0).isBefore(moment().startOf('day')) &&
            !block.completedDate)
      )
      // Only show RPAs and capture blocks
      .filter((block) => [BlockType.RESULT, BlockType.CAPTURE].includes(block.type))
      // Sort by date
      .sort((a, b) => new Date(a.scheduledDate) - new Date(b.scheduledDate));

    // Split block ids by day
    const blockIdsByDay = [];
    filteredAndSortedBlocks.forEach((block) => {
      timeScopes[block.id] = {};
      let scopeNumber = null;
      let timeScopeTitle = null;
      let timeScopeFormattedDate = '';

      // Overdue
      if (
        !block.completedDate &&
        moment(block.scheduledDate || 0).isBefore(moment().startOf('day'))
      ) {
        scopeNumber = 0;
        timeScopeTitle = 'Overdue';
        timeScopes[block.id].overdue = true;
      }

      // Day
      else {
        scopeNumber = parseInt(moment(block.scheduledDate).format('ggggwwe'));

        // Today
        if (moment(block.scheduledDate).isSame(moment(), 'day')) {
          timeScopeTitle = 'Today';
          timeScopeFormattedDate = moment(block.scheduledDate).format('dddd MMM Do, YYYY');
        }

        // Tomorrow
        else if (moment(block.scheduledDate).isSame(moment().add(1, 'day'), 'day')) {
          timeScopeTitle = 'Tomorrow';
          timeScopeFormattedDate = moment(block.scheduledDate).format('dddd MMM Do, YYYY');
        }

        // Any other day
        else {
          timeScopeTitle = moment(block.scheduledDate).format('dddd');
          timeScopeFormattedDate = moment(block.scheduledDate).format('MMM Do, YYYY');
        }
      }

      timeScopes[block.id].timeScopeTitle = timeScopeTitle;
      timeScopes[block.id].timeScopeFormattedDate = timeScopeFormattedDate;

      if (!blockIdsByDay[scopeNumber]) blockIdsByDay[scopeNumber] = [];
      blockIdsByDay[scopeNumber].push(block.id);
    });

    return blockIdsByDay;
  }

  function rescheduleOverdue(momentDate) {
    const updates = [];
    blockIdsByTimeScope.forEach((blockIds) => {
      blockIds.forEach((blockId) => {
        if (timeScopes[blockId].overdue) {
          updates.push({
            block: getBlock(blockId),
            props: { scheduledDate: momentDate.startOf('day').format() },
          });
        }
      });
    });
    updateBlock(
      updates.map((u) => u.block),
      updates.map((u) => u.props)
    );
  }

  // Drag and Drop
  function onDragEnd(result) {
    const { type } = result;

    // Handle actions
    if (type === 'action') dropAction(result);
  }
};

export default Actions;
