import React, { memo, useContext } from 'react';

// Material UI
import { Container } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../contexts/AppStateContext';
import GoalsDrawer from './GoalsDrawer/GoalsDrawer';
import Category from '../../components/Category/Category';
import PlanList from '../../components/PlanList/PlanList';

const Goals = memo(() => {
  const classes = useStyles();
  const { selectedCategoryId } = useContext(AppStateContext);

  return (
    <>
      <GoalsDrawer />

      <Container>
        <div className={classes.toolbar} />
        <Category categoryId={selectedCategoryId} />
        <PlanList categoryId={selectedCategoryId} />
      </Container>
    </>
  );
});

export default Goals;
