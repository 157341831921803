import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: theme.drawer.width,
    flexShrink: 0,
  },
  drawerPaper: (isTabletOrMobile) => ({
    top: isTabletOrMobile ? 0 : 64,
    width: theme.drawer.width,
    overflowX: 'hidden',
    backgroundColor:
      isTabletOrMobile && theme.palette.background.paperOpaque
        ? theme.palette.background.paperOpaque
        : theme.palette.background.paper,
    backdropFilter: 'blur(30px)',
  }),
}));
