import React, { useState } from 'react';

// Material UI
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const root = {
  position: 'relative',
  cursor: 'pointer'
  // overflow: 'hidden',
};

const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.secondary.main }
  }
}));

const GalleryImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  onImageClick,
  onDeleteClick
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  if (direction === 'column') {
    root.position = 'absolute';
    root.left = left;
    root.top = top;
  }

  return (
    <div
      style={{ margin, height: photo.height, width: photo.width, ...root }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <IconButton
          style={{ right: '4px', top: '4px', position: 'absolute', zIndex: '1' }}
          onClick={() => onDeleteClick(photo.filename)}
          className={classes.customHoverFocus}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <img
        src={photo.src}
        width={photo.width}
        height={photo.height}
        alt={photo.alt}
        onClick={event => onImageClick(event, { photo, index })}
      />
    </div>
  );
};

export default GalleryImage;
