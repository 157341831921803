import React, { useEffect, useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

// Lyfeplan
import GalleryImage from './GalleryImage/GalleryImage';

const VisionBoard = ({ images, onDelete, onLoad }) => {
  const [formattedImages, setFormattedImages] = React.useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // Close Lightbox
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    console.log(images);
    const loadImageUrls = async () => {
      onLoad && onLoad(true);

      // Get image URL and format details for React Grid Gallery
      const imagesWithUrls = await Promise.all(
        images.map(async (image) => ({
          // react-photo-gallery
          src: await getImgFromS3(image.thumbKey), // thumb
          width: image.thumbWidth,
          height: image.thumbHeight,
          alt: null,
          // Lyfeplan
          srcBig: await getImgFromS3(image.key), // big
          filename: image.key,
        }))
      );

      setFormattedImages(imagesWithUrls);
      onLoad && onLoad(false);
    };
    if (Array.isArray(images) && images.length) loadImageUrls();
  }, [images, onLoad]);

  const getImgFromS3 = async (key) => {
    if (!key) return;
    const url = await Storage.get(key, {
      level: 'protected',
      contentType: 'image/*',
    }).catch((err) => console.log(err));
    return url;
  };

  const imageRenderer = useCallback(({ index, left, top, key, photo }) => {
    // Open Lightbox
    const openLightbox = (event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    };

    // Delete image
    const deleteImage = (filename) => {
      // axios.get('/files/deleteImages', { params: { filename } }).then(res => {
      //   onDelete && onDelete(filename);
      // });
    };

    return (
      <GalleryImage
        key={key}
        margin={'2px'}
        index={index}
        photo={photo}
        left={left}
        top={top}
        onImageClick={openLightbox}
        onDeleteClick={deleteImage}
      />
    );
  }, []);

  return formattedImages.length ? (
    <>
      <Gallery photos={formattedImages} targetRowHeight={150} renderImage={imageRenderer} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={formattedImages.map((image) => ({
                source: {
                  thumbnail: image.src,
                  regular: image.srcBig,
                  fullscreen: image.srcBig,
                  download: image.srcBig,
                },
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  ) : null;
};

export default VisionBoard;
