import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  cardPadding: {
    padding: theme.spacing(2),
  },
  cardBottom: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  totals: {
    display: 'flex',
  },
  must: {
    paddingLeft: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  strike: {
    textDecoration: 'line-through',
    color: theme.palette.grey[500],
  },
  purpose: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  dragHandleIcon: {
    padding: theme.spacing(1.5),
  },
}));
