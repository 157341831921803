import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 10,
    backdropFilter: 'blur(30px)',
  },
  menuButton: {
    marginRight: 0,
  },
  tabs: {
    position: 'relative',
    marginLeft: theme.spacing(1),
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tab: {
    minWidth: '10px',
  },
  grow: {
    flexGrow: 1,
  },
}));
