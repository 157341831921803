import React, { createContext, useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from '@aws-amplify/core';
import moment from 'moment';

import {
  Profile,
  Area,
  Category,
  VisionBoardImage,
  Plan,
  Goal,
  Scope,
  Block,
  BlockType,
  Action,
} from '../models';

export const StoreContext = createContext();

const StoreContextProvider = (props) => {
  // console.log('StoreContextProvider rendering...');

  const [dataStoreReady, setDataStoreReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const [profile, setProfile] = useState(null);
  const [areas, setAreas] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visionBoardImages, setVisionBoardImages] = useState([]);
  const [plans, setPlans] = useState([]);
  const [goals, setGoals] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [actions, setActions] = useState([]);

  Hub.listen('datastore', async (hubData) => {
    const { event } = hubData.payload;
    if (event === 'ready') {
      // console.log('DataStore Ready');
      setDataStoreReady(true);
    }
  });

  useEffect(() => {
    const loadProfile = async () => setProfile((await DataStore.query(Profile))?.[0]);
    const loadAreas = async () => setAreas(await DataStore.query(Area));
    const loadCategories = async () => setCategories(await DataStore.query(Category));
    const loadVisionBoardImages = async () =>
      setVisionBoardImages(await DataStore.query(VisionBoardImage));
    const loadPlans = async () => setPlans(await DataStore.query(Plan));
    const loadGoals = async () => setGoals(await DataStore.query(Goal));
    const loadBlocks = async () => setBlocks(await DataStore.query(Block));
    const loadActions = async () => setActions(await DataStore.query(Action));

    if (dataStoreReady) {
      console.log('Loading all things...');

      (async () => {
        try {
          await Promise.all([
            loadProfile(),
            loadAreas(),
            loadCategories(),
            loadVisionBoardImages(),
            loadPlans(),
            loadGoals(),
            loadBlocks(),
            loadActions(),
          ]);
          console.log('All things loaded!');
          setLoading(false);
        } catch (err) {
          setError('err.message');
          console.log(err);
          setLoading(false);
        }
      })();
    }

    const profileSubscription = DataStore.observe(Profile).subscribe(loadProfile);
    const areasSubscription = DataStore.observe(Area).subscribe(loadAreas);
    const categoriesSubscription = DataStore.observe(Category).subscribe(loadCategories);
    const visionBoardImageSubscription =
      DataStore.observe(VisionBoardImage).subscribe(loadVisionBoardImages);
    const plansSubscription = DataStore.observe(Plan).subscribe(loadPlans);
    const goalsSubscription = DataStore.observe(Goal).subscribe(loadGoals);
    const blocksSubscription = DataStore.observe(Block).subscribe(loadBlocks);
    const actionsSubscription = DataStore.observe(Action).subscribe(loadActions);

    return () => {
      profileSubscription.unsubscribe();
      areasSubscription.unsubscribe();
      categoriesSubscription.unsubscribe();
      visionBoardImageSubscription.unsubscribe();
      plansSubscription.unsubscribe();
      goalsSubscription.unsubscribe();
      blocksSubscription.unsubscribe();
      actionsSubscription.unsubscribe();
    };
  }, [dataStoreReady]);

  // ---------------------------------------------------------------------
  // Profile
  // ---------------------------------------------------------------------

  const updateProfile = (props = {}) => {
    // TODO: Validate props

    // Update or create profile
    const newProfile = profile
      ? Profile.copyOf(profile, (updated) => Object.assign(updated, props))
      : new Profile({ ...props });

    // Update DataStore
    DataStore.save(newProfile);

    // Update Context
    setProfile(newProfile);

    return newProfile;
  };

  // ---------------------------------------------------------------------
  // Areas
  // ---------------------------------------------------------------------

  const addArea = (props = {}) => {
    try {
      // Make sure we have a profile
      if (!profile) return false;

      // Create new area
      const newArea = new Area({ ...props });

      // Add to DataStore
      const newAreas = updateDataStore(newArea, {}, areas, Area);

      // Add to Context
      setAreas(newAreas);

      // Add to parent profile
      const areaIds = jsonToArray(profile.areaIds);
      const newAreaIds = [...areaIds, newArea.id];
      updateProfile({ areaIds: JSON.stringify(newAreaIds) });

      return newArea;
    } catch (err) {
      console.log(err);
    }
  };

  const getArea = (id) => id && areas.find((a) => a.id === id);

  const updateArea = (area, props) => {
    if (area && props) {
      // Update DataStore
      const newAreas = updateDataStore(area, props, areas, Area);

      // Update Context
      setAreas(newAreas);

      return true;
    }
  };

  const deleteArea = (areaId) => {
    if (areaId) {
      try {
        const area = getArea(areaId);
        const categoryIds = [...jsonToArray(area.categoryIds)];

        // Delete from children categories
        categoryIds.forEach((categoryId) => deleteCategory(categoryId));

        // Delete from DataStore
        DataStore.delete(area);

        // Delete from Context
        setAreas(areas.filter((a) => a.id !== areaId));

        // Delete from parent profile
        const areaIds = JSON.stringify(jsonToArray(profile.areaIds).filter((id) => id !== areaId));
        updateProfile({ areaIds });

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ---------------------------------------------------------------------
  // Categories
  // ---------------------------------------------------------------------

  const addCategory = (props) => {
    if (props?.areaId) {
      try {
        // Make sure areaId exists
        const area = getArea(props.areaId);
        if (!area) return false;

        // Create new category
        const newCategory = new Category({ ...props });

        // Add to DataStore
        const newCategories = updateDataStore(newCategory, {}, categories, Category);

        // Add to Context
        setAreas(newCategories);

        // Add to parent area
        const categoryIds = jsonToArray(area.categoryIds);
        const newCategoryIds = [...categoryIds, newCategory.id];
        updateArea(area, { categoryIds: JSON.stringify(newCategoryIds) });

        return newCategory;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getCategory = (id) => id && categories.find((c) => c.id === id);

  const getCategoriesByArea = (areaId) =>
    categories.filter((category) => category.areaId === areaId);

  const updateCategory = (category, props) => {
    if (category && props) {
      // Update DataStore
      const newCategories = updateDataStore(category, props, categories, Category);

      // Update Context
      setCategories(newCategories);

      return true;
    }
  };

  const deleteCategory = (categoryId) => {
    if (categoryId) {
      try {
        const category = getCategory(categoryId);
        const area = getArea(category.areaId);
        const planIds = [...jsonToArray(category.planIds)];

        // Delete from children plans
        planIds.forEach((planId) => deletePlan(planId));

        // Delete from DataStore
        DataStore.delete(category);

        // Delete from Context
        setCategories(categories.filter((c) => c.id !== categoryId));

        // Delete from parent area
        const categoryIds = JSON.stringify(
          jsonToArray(area.categoryIds).filter((id) => id !== category.id)
        );
        updateArea(area, { categoryIds });

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ---------------------------------------------------------------------
  // Vision Board Images
  // ---------------------------------------------------------------------

  const addVisionBoardImage = (categoryId, props) => {
    console.log('addVisionBoardImage', categoryId, props);
    if (
      categoryId &&
      props.key &&
      props.width &&
      props.height &&
      props.thumbKey &&
      props.thumbWidth &&
      props.thumbHeight
    ) {
      try {
        const category = getCategory(categoryId);

        // Create new vision board image
        const newVisionBoardImage = new VisionBoardImage({
          areaId: category.areaId,
          categoryId,

          key: props.key,
          width: props.width,
          height: props.height,

          thumbKey: props.thumbKey,
          thumbWidth: props.thumbWidth,
          thumbHeight: props.thumbHeight,
        });
        console.log('newVisionBoardImage', newVisionBoardImage);

        // Add to DataStore
        const newVisionBoardImages = updateDataStore(
          newVisionBoardImage,
          {},
          visionBoardImages,
          VisionBoardImage
        );

        // Add to Context
        setVisionBoardImages(newVisionBoardImages);

        // Add to parent category
        const visionBoardImageIds = jsonToArray(category.visionBoardImageIds);
        const newVisionBoardImageIds = [...visionBoardImageIds, newVisionBoardImage.id];
        updateCategory(category, { visionBoardImageIds: JSON.stringify(newVisionBoardImageIds) });

        return newVisionBoardImage;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getVisionBoardImage = (id) => id && visionBoardImages.find((i) => i.id === id);

  const getVisionBoardImages = (ids) => ids && visionBoardImages.filter((i) => ids.includes(i.id));

  const updateVisionBoardImage = (visionBoardImage, props) => {
    if (visionBoardImage && props) {
      // Update DataStore
      const newVisionBoardImages = updateDataStore(
        visionBoardImage,
        props,
        visionBoardImages,
        VisionBoardImage
      );

      // Update Context
      setVisionBoardImages(newVisionBoardImages);

      return true;
    }
  };

  const deleteVisionBoardImage = async (visionBoardImageId) => {
    if (visionBoardImageId) {
      const visionBoardImage = getVisionBoardImage(visionBoardImageId);
      const category = getCategory(visionBoardImage.categoryId);

      // Delete from DataStore
      DataStore.delete(visionBoardImage);

      // Delete from Context
      setVisionBoardImages(visionBoardImages.filter((i) => i.id !== visionBoardImageId));

      // Delete from parent category
      const visionBoardImageIds = jsonToArray(category.visionBoardImageIds);
      const newVisionBoardImageIds = visionBoardImageIds.filter((id) => id !== visionBoardImageId);
      updateCategory(category, { visionBoardImageIds: JSON.stringify(newVisionBoardImageIds) });

      return true;
    }
  };

  // ---------------------------------------------------------------------
  // Plans
  // ---------------------------------------------------------------------

  const addPlan = (categoryId, props) => {
    if (categoryId && props.year) {
      try {
        const category = getCategory(categoryId);

        // Create new plan
        const newPlan = new Plan({ areaId: category.areaId, categoryId, ...props });

        // Add to DataStore
        const newPlans = updateDataStore(newPlan, {}, plans, Plan);

        // Add to Context
        setPlans(newPlans);

        // Add to parent category
        const planIds = jsonToArray(category.planIds);
        const newPlanIds = [...planIds, newPlan.id];
        updateCategory(category, { planIds: JSON.stringify(newPlanIds) });

        return newPlan;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getPlan = (id) => id && plans.find((p) => p.id === id);

  const updatePlan = (plan, props) => {
    if (plan && props) {
      // Update DataStore
      const newPlans = updateDataStore(plan, props, plans, Plan);

      // Update Context
      setPlans(newPlans);

      return true;
    }
  };

  const deletePlan = async (planId) => {
    if (planId) {
      const plan = getPlan(planId);
      const category = getCategory(plan.categoryId);
      const goalIds = [
        ...jsonToArray(plan.yGoalIds),
        ...jsonToArray(plan.q1GoalIds),
        ...jsonToArray(plan.q2GoalIds),
        ...jsonToArray(plan.q3GoalIds),
        ...jsonToArray(plan.q4GoalIds),
      ];

      // Delete from children goals
      goalIds.forEach((goalId) => deleteGoal(goalId));

      // Delete from DataStore
      DataStore.delete(plan);

      // Delete from Context
      setPlans(plans.filter((p) => p.id !== planId));

      // Delete from parent category
      const planIds = jsonToArray(category.planIds);
      const newPlanIds = planIds.filter((id) => id !== planId);
      updateCategory(category, { planIds: JSON.stringify(newPlanIds) });

      return true;
    }
  };

  // ---------------------------------------------------------------------
  // Goals
  // ---------------------------------------------------------------------

  const addGoal = (planId, props) => {
    if (planId && props.scope) {
      try {
        const plan = getPlan(planId);

        // Create new goal
        const newGoal = new Goal({
          areaId: plan.areaId,
          categoryId: plan.categoryId,
          planId,
          ...props,
        });

        // Add to DataStore
        const newGoals = updateDataStore(newGoal, {}, goals, Goal);

        // Add to Context
        setGoals(newGoals);

        // Add to parent plan
        const scopedGoalIds = props.scope.toLowerCase() + 'GoalIds';
        const goalIds = jsonToArray(plan[scopedGoalIds]);
        const newGoalIds = [...goalIds, newGoal.id];
        updatePlan(plan, { [scopedGoalIds]: JSON.stringify(newGoalIds) });

        return newGoal;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getGoal = (id) => id && goals.find((g) => g.id === id);

  const updateGoal = (goal, props) => {
    if (goal && props) {
      // Update DataStore
      const newGoals = updateDataStore(goal, props, goals, Goal);

      // Update Context
      setGoals(newGoals);

      return true;
    }
  };

  const deleteGoal = async (goalId) => {
    if (goalId) {
      const goal = getGoal(goalId);
      const plan = getPlan(goal.planId);
      const scopedGoalIds = goal.scope.toLowerCase() + 'GoalIds';

      // Delete from DataStore
      DataStore.delete(goal);

      // Delete from Context
      setGoals(goals.filter((g) => g.id !== goalId));

      // Delete from parent plan
      const goalIds = jsonToArray(plan[scopedGoalIds]);
      const newGoalIds = goalIds.filter((id) => id !== goalId);
      updatePlan(plan, { [scopedGoalIds]: JSON.stringify(newGoalIds) });

      return true;
    }
  };

  // ---------------------------------------------------------------------
  // Blocks
  // ---------------------------------------------------------------------

  const addBlock = (props = {}) => {
    if (profile && props.type) {
      // Create new block
      const newBlock = new Block({ ...props });

      // Add to DataStore
      const newBlocks = updateDataStore(newBlock, {}, blocks, Block);

      // Add to Context
      setBlocks(newBlocks);

      if (props.parentBlockId) {
        // Add to parent block
        const parentBlock = getBlock(props.parentBlockId);
        const blockIds = jsonToArray(parentBlock.blockIds);
        const newBlockIds = [...blockIds, newBlock.id];
        updateBlock(parentBlock, { blockIds: JSON.stringify(newBlockIds) });
      } else if (props.type === BlockType.PROJECT) {
        // Add to profile
        const projectIds = jsonToArray(profile.projectIds);
        const newProjectIds = [...projectIds, newBlock.id];
        updateProfile({ projectIds: JSON.stringify(newProjectIds) });
      }

      return newBlock;
    }
  };

  const getBlock = (id) => id && blocks.find((b) => b.id === id);

  const updateBlock = (block, props) => {
    if (block && props) {
      // Update DataStore
      const newBlocks = updateDataStore(block, props, blocks, Block);

      // Update Context
      setBlocks(newBlocks);

      return true;
    }
  };

  const deleteBlock = (blockId) => {
    if (blockId) {
      try {
        const block = getBlock(blockId);
        const actionIds = [...jsonToArray(block.actionIds)];

        // Delete children actions
        actionIds.forEach((actionId) => deleteAction(actionId));

        // Delete from DataStore
        DataStore.delete(block);

        // Delete from Context
        setBlocks(blocks.filter((b) => b.id !== blockId));

        // Delete from parent
        if (block.parentBlockId) {
          // Delete from parent block
          const parentBlock = getBlock(block.parentBlockId);
          const blockIds = JSON.stringify(
            jsonToArray(parentBlock.blockIds).filter((id) => id !== blockId)
          );
          updateBlock(parentBlock, { blockIds });
        } else {
          // Delete from profile
          const projectIds = JSON.stringify(
            jsonToArray(profile.projectIds).filter((id) => id !== blockId)
          );
          updateProfile({ projectIds });
        }

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ---------------------------------------------------------------------
  // Actions
  // ---------------------------------------------------------------------

  const addAction = (props) => {
    if ((props = getValidActionProps(props))) {
      try {
        // Make sure blockId exists
        const block = getBlock(props.blockId);
        if (!block) return false;

        // Create new action
        const newAction = new Action({ ...props });

        // Add to DataStore
        const newActions = updateDataStore(newAction, {}, actions, Action);

        // Add to Context
        setActions(newActions);

        // Add to parent block
        const actionIds = jsonToArray(block.actionIds);
        const newActionIds = [...actionIds, newAction.id];
        updateBlock(block, { actionIds: JSON.stringify(newActionIds) });

        return newAction;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getAction = (id) => id && actions.find((a) => a.id === id);

  const getActionsByBlock = (blockId) => actions.filter((a) => a.blockId === blockId);

  const updateAction = (action, props) => {
    if (action && (props = getValidActionProps(props))) {
      try {
        // Update DataStore
        const newActions = updateDataStore(action, props, actions, Action);

        // Update Context
        setActions(newActions);

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteAction = (actionId) => {
    if (actionId) {
      try {
        const action = getAction(actionId);
        const block = getBlock(action.blockId);

        // Delete from DataStore
        DataStore.delete(action);

        // Delete from Context
        setActions(actions.filter((a) => a.id !== actionId));

        // Delete from parent block
        const actionIds = JSON.stringify(
          jsonToArray(block.actionIds).filter((id) => id !== actionId)
        );
        updateBlock(block, { actionIds });

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getValidActionProps = (props = {}) => {
    if (props.blockId && !getBlock(props.blockId)) return null;
    if (props.duration !== null) props.duration = parseInt(props.duration);
    return props;
  };

  const toggleCompleteAction = (action, props) => {
    if (action) {
      try {
        // Update action
        const completedDate = action.completedDate ? null : moment().format();
        updateAction(action, { completedDate });

        // Update parent block
        const block = getBlock(action.blockId);
        if (completedDate) {
          const actionIds = jsonToArray(block.actionIds).filter((id) => id !== action.id);
          const completedActionIds = [...jsonToArray(block.completedActionIds), action.id];
          updateBlock(block, {
            actionIds: JSON.stringify(actionIds),
            completedActionIds: JSON.stringify(completedActionIds),
          });
        } else {
          const actionIds = [...jsonToArray(block.actionIds), action.id];
          const completedActionIds = jsonToArray(block.completedActionIds).filter(
            (id) => id !== action.id
          );
          updateBlock(block, {
            actionIds: JSON.stringify(actionIds),
            completedActionIds: JSON.stringify(completedActionIds),
          });
        }

        return true;
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ---------------------------------------------------------------------
  // Utils
  // ---------------------------------------------------------------------

  // Update a model item in DataStore and return Context item list
  const updateDataStore = (models, props, list, Model) => {
    // Convert model & props to arrays
    if (!Array.isArray(models) && !Array.isArray(props)) {
      models = [models];
      props = [props];
    }

    const newList = [...list];

    models.forEach((model, i) => {
      const newModel = Model.copyOf(model, (updated) => Object.assign(updated, props[i]));
      const listIndex = newList.findIndex((item) => item === model);
      newList[listIndex] = newModel;
      DataStore.save(newModel);
    });

    return newList;
  };

  const jsonToArray = (json) => JSON.parse(json || null) || [];

  const cleanUp = () => {
    console.log('Cleaning...');

    // Remove deleted projectIds from profile
    const projectIds = jsonToArray(profile.projectIds);
    if (projectIds.length) {
      const cleanProjectIds = projectIds?.filter?.((id) => getBlock(id));
      if (projectIds.length !== cleanProjectIds?.length) {
        console.log('Cleaning projectIds of profile');
        updateProfile({ projectIds: JSON.stringify(cleanProjectIds) });
      }
    }

    // Remove deleted actionIds and completedActionIds from every block
    blocks.forEach((block) => {
      const actionIds = jsonToArray(block.actionIds);
      if (actionIds.length) {
        const cleanActionIds = actionIds?.filter?.((id) => getAction(id));
        if (actionIds.length !== cleanActionIds?.length) {
          console.log('Cleaning actionIds of blockId: ', block.id);
          updateBlock(block, { actionIds: JSON.stringify(cleanActionIds) });
        }
      }
      const completedActionIds = jsonToArray(block.completedActionIds);
      console.log({ actionIds, completedActionIds });
      if (completedActionIds.length) {
        const cleanCompletedActionIds = completedActionIds?.filter?.((id) => getAction(id));
        if (completedActionIds.length !== cleanCompletedActionIds?.length) {
          console.log('Cleaning completedActionIds of blockId: ', block.id);
          updateBlock(block, { completedActionIds: JSON.stringify(cleanCompletedActionIds) });
        }
      }
    });

    console.log('Cleaning done.');
  };

  return (
    <StoreContext.Provider
      value={{
        loading,
        error,

        profile,
        updateProfile,

        areas,
        addArea,
        getArea,
        updateArea,
        deleteArea,

        categories,
        addCategory,
        getCategory,
        getCategoriesByArea,
        updateCategory,
        deleteCategory,

        visionBoardImages,
        addVisionBoardImage,
        getVisionBoardImage,
        getVisionBoardImages,
        updateVisionBoardImage,
        deleteVisionBoardImage,

        plans,
        addPlan,
        getPlan,
        updatePlan,
        deletePlan,

        goals,
        addGoal,
        getGoal,
        updateGoal,
        deleteGoal,
        Scope,

        blocks,
        addBlock,
        getBlock,
        updateBlock,
        deleteBlock,
        BlockType,

        actions,
        addAction,
        getAction,
        getActionsByBlock,
        updateAction,
        deleteAction,
        toggleCompleteAction,

        jsonToArray,
        cleanUp,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
