import React, { useContext } from 'react';
import moment from 'moment';

// Material UI
import { Tooltip, Checkbox, IconButton, Typography } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import useMenu from '../../../../hooks/useMenu';
import { StoreContext } from '../../../../contexts/StoreContext';
import { prettyDuration } from '../../../../utils/utils';

const DisplayedAction = ({ action, setEditing, scheduledDateText }) => {
  const classes = useStyles();
  const { toggleCompleteAction, deleteAction } = useContext(StoreContext);

  const { menu, onMenuClick } = useMenu([
    // { label: 'Rename', onClick: () => {} },
    // { label: 'Share', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteAction(action.id) },
  ]);

  return (
    <div className={classes.displayedAction}>
      {/* Completed checkbox */}
      <Tooltip
        title={
          action.completedDate
            ? `Completed on ${moment(action.completedDate).format('YYYY-MM-DD')}`
            : ''
        }
        placement="right"
        style={{ marginTop: 3 }}
      >
        <Checkbox
          edge="start"
          onClick={() => toggleCompleteAction(action)}
          checked={!!action.completedDate}
        />
      </Tooltip>

      {/* Action name */}
      <Typography
        variant="body1"
        style={{ flexGrow: 1, marginTop: 12, marginBottom: 12 }}
        className={!action.name ? classes.empty : action.completedDate ? classes.strike : null}
        onClick={() => setEditing(true)}
      >
        {action.name || 'New action'}
      </Typography>

      {/* Scheduled date */}
      {action.scheduledDate && (
        <Typography
          variant="body1"
          style={{ marginTop: 12, marginBottom: 12, marginRight: 12 }}
          className={classes.duration}
        >
          {scheduledDateText}
        </Typography>
      )}

      {/* Duration */}
      {action.duration && (
        <Typography
          variant="body1"
          style={{ marginTop: 12, marginBottom: 12 }}
          className={classes.duration}
        >
          {prettyDuration(action.duration)}
        </Typography>
      )}

      {/* Menu icon */}
      <IconButton onClick={onMenuClick}>
        <MoreVertIcon />
      </IconButton>
      {menu}
    </div>
  );
};

export default DisplayedAction;
