import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  fab: {
    marginTop: theme.spacing(2),
    alignSelf: 'center'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));
