import { useContext } from 'react';

// Lyfeplan
import { StoreContext } from '../contexts/StoreContext';

// Drop Action Hook
const useDropBlock = () => {
  const { getBlock, updateBlock, jsonToArray } = useContext(StoreContext);

  function dropBlock(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    // Handle blocks
    if (type === 'block') {
      const sourceBlock = getBlock(source.droppableId);
      const destinationBlock = getBlock(destination.droppableId);
      const newSourceBlockIds = jsonToArray(sourceBlock.blockIds);

      // Remove block from source block
      newSourceBlockIds.splice(source.index, 1);

      // If source and destination block are the same
      if (sourceBlock === destinationBlock) {
        // Add block in the new spot
        newSourceBlockIds.splice(destination.index, 0, draggableId);
        updateBlock(sourceBlock, { blockIds: JSON.stringify(newSourceBlockIds) });
      }

      // If destination is different from source
      else {
        const newDestinationBlockIds = jsonToArray(destinationBlock.blockIds);
        const movedBlock = getBlock(draggableId);

        // Add block in the new spot
        newDestinationBlockIds.splice(destination.index, 0, draggableId);

        updateBlock(
          [sourceBlock, destinationBlock, movedBlock],
          [
            { blockIds: JSON.stringify(newSourceBlockIds) },
            { blockIds: JSON.stringify(newDestinationBlockIds) },
            { parentBlockId: JSON.stringify(destinationBlock.id) },
          ]
        );
      }
    }
  }

  return dropBlock;
};

export default useDropBlock;
