import React, { useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

// Lyfeplan
import { StoreContext } from '../../contexts/StoreContext';
import CaptureBlock from './CaptureBlock/CaptureBlock';
import ResultBlock from './ResultBlock/ResultBlock';
import ProjectBlock from './ProjectBlock/ProjectBlock';

const Block = ({ blockId, showDate, dragHandleProps, outerDragDropContext }) => {
  const {
    getBlock,
    // actions, updateBlock, updateAction,
    BlockType,
  } = useContext(StoreContext);

  const block = getBlock(blockId);
  if (!block) return null;

  const theBlock =
    block.type === BlockType.CAPTURE ? (
      <CaptureBlock blockId={blockId} dragHandleProps={dragHandleProps} showDate={showDate} />
    ) : block.type === BlockType.RESULT ? (
      <ResultBlock blockId={blockId} dragHandleProps={dragHandleProps} showDate={showDate} />
    ) : block.type === BlockType.PROJECT ? (
      <ProjectBlock blockId={blockId} dragHandleProps={dragHandleProps} showDate={showDate} />
    ) : null;

  if (dragHandleProps || outerDragDropContext) return theBlock;

  return <DragDropContext onDragEnd={onDragEnd}>{theBlock}</DragDropContext>;

  // Drag and Drop
  function onDragEnd(result) {
    alert('TODO: onDragEnd in Block.js');
    return;
    // console.log('>>>>>>>>>>> onDragEnd from Block.js <<<<<<<<<<<<<');
    // const { destination, source, draggableId, type } = result;

    // if (!destination) return;
    // if (destination.droppableId === source.droppableId && destination.index === source.index)
    //   return;

    // // Handle actions
    // if (type === 'action') {
    //   const sourceBlock = blocks.[source.droppableId];
    //   const destinationBlock = blocks[destination.droppableId];
    //   const newSourceActionIds = Array.from(sourceBlock.actionIds);

    //   // Remove action from source block
    //   newSourceActionIds.splice(source.index, 1);

    //   // If source and destination block are the same, add action in the right spot
    //   if (sourceBlock === destinationBlock)
    //     newSourceActionIds.splice(destination.index, 0, draggableId);

    //   const newSourceBlock = {
    //     ...sourceBlock,
    //     actionIds: newSourceActionIds,
    //   };

    //   updateBlock(newSourceBlock);

    //   // If destination is different from source, update it too
    //   if (sourceBlock !== destinationBlock) {
    //     const newDestinationActionIds = Array.from(destinationBlock.actionIds);
    //     const action = actions[draggableId];

    //     // Add action in the right spot
    //     newDestinationActionIds.splice(destination.index, 0, draggableId);

    //     const newDestinationBlock = {
    //       ...destinationBlock,
    //       actionIds: newDestinationActionIds,
    //     };

    //     const newAction = {
    //       ...action,
    //       blockId: destinationBlock.id,
    //     };

    //     updateBlock(newDestinationBlock);
    //     updateAction(newAction);
    //   }
    // }

    // // Handle blocks
    // if (type === 'block') {
    //   const sourceBlock = blocks[source.droppableId];
    //   const destinationBlock = blocks[destination.droppableId];
    //   const newSourceBlockIds = Array.from(sourceBlock.blockIds);

    //   // Remove block from source block
    //   newSourceBlockIds.splice(source.index, 1);

    //   // If source and destination block are the same, add block in the right spot
    //   if (sourceBlock === destinationBlock)
    //     newSourceBlockIds.splice(destination.index, 0, draggableId);

    //   const newSourceBlock = {
    //     ...sourceBlock,
    //     blockIds: newSourceBlockIds,
    //   };

    //   updateBlock(newSourceBlock);

    //   // If destination is different from source, update it too
    //   if (sourceBlock !== destinationBlock) {
    //     const newDestinationBlockIds = Array.from(destinationBlock.blockIds);
    //     const block = blocks[draggableId];

    //     // Add block in the right spot
    //     newDestinationBlockIds.splice(destination.index, 0, draggableId);

    //     const newDestinationBlock = {
    //       ...destinationBlock,
    //       blockIds: newDestinationBlockIds,
    //     };

    //     const newBlock = {
    //       ...block,
    //       blockId: destinationBlock.id,
    //     };

    //     updateBlock(newDestinationBlock);
    //     updateBlock(newBlock);
    //   }
    // }
  }
};

export default Block;
