import React from 'react';

// Material UI
import { Typography, IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation as CalendarIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { prettyDate } from '../../../utils/utils';

const BlockDatePicker = ({ block, showDate, isOpenSchedule, setIsOpenSchedule, updateBlock }) => {
  const classes = useStyles();

  return (
    <>
      {showDate && (
        <Typography variant="body1" onClick={() => setIsOpenSchedule(true)}>
          {prettyDate(block.scheduledDate)}
        </Typography>
      )}
      <DatePicker
        variant="inline"
        label=""
        format="YYYY-MM-DD"
        margin="normal"
        open={isOpenSchedule}
        onOpen={() => setIsOpenSchedule(true)}
        onClose={() => setIsOpenSchedule(false)}
        autoOk
        disableToolbar
        value={block.scheduledDate || null}
        onChange={(momentDate) =>
          updateBlock(block, { scheduledDate: momentDate ? momentDate.format() : null })
        }
        style={{ margin: 0 }}
        InputProps={{ className: classes.customDatePicker }}
      />
      <IconButton color="inherit" onClick={() => setIsOpenSchedule(true)}>
        <CalendarIcon />
      </IconButton>
    </>
  );
};

export default BlockDatePicker;
