import React, { useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Material UI
import { Grid } from '@material-ui/core';

// Lyfeplan
import useDropAction from '../../hooks/useDropAction';
import useDropBlock from '../../hooks/useDropBlock';
import { AppStateContext } from '../../contexts/AppStateContext';
import { StoreContext } from '../../contexts/StoreContext';
import Block from '../Block/Block';

const BlockList = ({ blockId, blockIds, dragEnabled }) => {
  const { showCompleted, timeScope } = useContext(AppStateContext);
  const { getBlock, jsonToArray } = useContext(StoreContext);
  const dropAction = useDropAction();
  const dropBlock = useDropBlock();

  if (blockId) {
    // Get blockIds from blockId
    blockIds = jsonToArray(getBlock(blockId).blockIds);
  } else {
    // Impossible to order if blockIds don't belong to a specific block
    dragEnabled = false;
  }

  // If no blockIds, nothing to show
  if (!blockIds || !blockIds.length) return null;

  let blockList = blockIds.map((blockId, index) => {
    const block = getBlock(blockId);
    if (!block) return null;
    if (block.completedDate && !showCompleted) return null;

    return dragEnabled ? (
      <Draggable draggableId={blockId} index={index} key={blockId}>
        {(provided, snapshot) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
          >
            <Block
              blockId={blockId}
              dragHandleProps={provided.dragHandleProps}
              showDate={timeScope === 'day' ? false : true}
              outerDragDropContext
            />
          </Grid>
        )}
      </Draggable>
    ) : (
      <Grid item xs={12} sm={12} md={12} key={blockId}>
        <Block
          blockId={blockId}
          showDate={timeScope === 'day' ? false : true}
          outerDragDropContext
        />
      </Grid>
    );
  });

  const droppableBlockList = dragEnabled && (
    <Droppable droppableId={blockId} type="block" style={{ width: '100%' }}>
      {(provided) => (
        <Grid container spacing={3} ref={provided.innerRef} {...provided.droppableProps}>
          {blockList}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );

  return <DragDropContext onDragEnd={onDragEnd}>{droppableBlockList || blockList}</DragDropContext>;

  // Drag and Drop
  function onDragEnd(result) {
    dropAction(result);
    dropBlock(result);
  }
};

export default BlockList;
