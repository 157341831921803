import React, { memo, useContext, useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

// Material UI
import { Grid, LinearProgress } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../contexts/StoreContext';
import CustomInput from '../UI/CustomInput/CustomInputWithButtons';
import CustomCard from '../UI/CustomCard/CustomCard';
// import Dropzone from '../UI/Dropzone/Dropzone';
import VisionBoard from './VisionBoard/VisionBoard';
import resizeImage from '../../utils/resizeImage';

const Category = memo(({ categoryId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const memoizedOnLoad = useCallback((state) => setLoading(state), []);
  const {
    getCategory,
    updateCategory,
    addVisionBoardImage,
    getVisionBoardImages,
    jsonToArray,
  } = useContext(StoreContext);

  const category = getCategory(categoryId);
  if (!category) return null;

  const pushImgToS3 = async (file, key) => {
    if (file === null) return;
    const res = await Storage.put(key, file, {
      level: 'protected',
      contentType: 'image/*',
    }).catch((err) => console.log(err));
    return res.key;
  };

  const handleAdd = async (event) => {
    event.persist();
    // Check there is some files to upload
    if (!event || !event.target || !event.target.files) return;
    const filesLength = event.target.files.length;

    // Loop through all selected files
    for (let i = 0; i < filesLength; i++) {
      const file = event.target.files[i];
      const splittedFilename = file.name.split('.');
      const fileExtension = splittedFilename.pop().toLowerCase();
      const filename = slugify(splittedFilename.join('.'));
      const uid = uuidv4();

      // Resize image & push to S3
      const image = await resizeImage(file, 1250);
      const newKey = `${filename}-${uid}.${fileExtension}`;
      const key = await pushImgToS3(image.file, newKey);

      // Resize thumbnail & push to S3
      const thumbnail = await resizeImage(file, 250);
      const newThumbKey = `${filename}-thumb-${uid}.${fileExtension}`;
      const thumbKey = await pushImgToS3(thumbnail.file, newThumbKey);

      // Add DB
      addVisionBoardImage(categoryId, {
        key,
        width: image.width,
        height: image.height,
        thumbKey,
        thumbWidth: thumbnail.width,
        thumbHeight: thumbnail.height,
      });
    }
  };

  const visionBoardImages = getVisionBoardImages(jsonToArray(category.visionBoardImageIds));

  return (
    <>
      <div className={classes.h5}>
        <CustomInput
          variant="h5"
          value={category.name}
          placeholder="New category"
          onChange={(value) => updateCategory(category, { name: value })}
        />
      </div>

      {/* <div className={classes.grow}> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomCard title="Ultimate Vision">
            <CustomInput
              value={category.vision}
              placeholder={'Describe your ultimate vision (the "what")...'}
              onChange={(value) => updateCategory(category, { vision: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="Ultimate Purpose">
            <CustomInput
              value={category.purpose}
              placeholder={'Describe your ultimate purpose (the "why")...'}
              onChange={(value) => updateCategory(category, { purpose: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="Roles">
            <CustomInput
              value={category.roles}
              onChange={(value) => updateCategory(category, { roles: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="3 To Thrive">
            <CustomInput
              value={category.threeToThrive}
              onChange={(value) => updateCategory(category, { threeToThrive: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="Resources">
            <CustomInput
              value={category.resources}
              onChange={(value) => updateCategory(category, { resources: value })}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard title="Vision Board">
            <input
              accept="image/*"
              id="button-add-picture"
              multiple
              type="file"
              onChange={handleAdd}
            />
            {visionBoardImages.length}

            {/* <Dropzone onFilesUploaded={onFilesUploaded} onLoad={memoizedOnLoad} />*/}

            {loading ? (
              <LinearProgress color="secondary" style={{ marginTop: 4, marginBottom: 4 }} />
            ) : (
              <div style={{ height: 4, marginTop: 4, marginBottom: 4 }}></div>
            )}

            {visionBoardImages && visionBoardImages.length ? (
              <VisionBoard
                images={visionBoardImages}
                // onDelete={onFileDelete}
                // onLoad={memoizedOnLoad}
              />
            ) : null}
          </CustomCard>
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );

  // function onFilesUploaded(filesDetails) {
  //   // Add images to visionBoard
  //   const newVisionBoard = [...category.visionBoard, ...filesDetails];
  //   updateCategory(category,{ visionBoard: newVisionBoard });
  // }

  // function onFileDelete(filename) {
  //   // Remove image from visionBoard
  //   const newVisionBoard = [...category.visionBoard];
  //   const imageIndex = newVisionBoard.findIndex((image) => image.filename === filename);
  //   newVisionBoard.splice(imageIndex, 1);
  //   updateCategory(category,{ visionBoard: newVisionBoard });
  // }
});

export default Category;
