import React from 'react';

// Material UI
import { Chip } from '@material-ui/core';

// Lyfeplan
import { STATUSES } from '../../../settings';

const StatusChip = ({ value, onClick, style }) => {
  return (
    <Chip
      size="small"
      label={STATUSES[value || 'toAchieve'].label}
      color="primary"
      style={{ backgroundColor: STATUSES[value || 'toAchieve'].color, ...style }}
      onClick={onClick}
      onFocus={event => event.target.blur()}
    />
  );
};

export default StatusChip;
