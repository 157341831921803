// Material UI
import { fade } from '@material-ui/core/styles/colorManipulator';

import mountains from '../assets/images/misty-mountains.jpg';

const lightTheme = {
  palette: {
    primary: {
      main: '#5f8a5c',
      lightest: fade('#5f8a5c', 0.1),
    },
    secondary: { main: '#9984bd' },
    tertiary: { main: 'grey' },
  },
};

const zenTheme = {
  palette: {
    primary: {
      main: '#445942',
      lightest: fade('#445942', 0.1),
    },
    secondary: { main: '#a1b88d' },
    tertiary: { main: '#c3d3b2' },
    background: {
      default: '#dad2ba',
      paper: '#f0ede3',
    },
  },
};

const darkTheme = {
  palette: {
    type: 'dark',
    primary: { main: '#445942' },
    secondary: { main: '#6c568f' },
    tertiary: { main: '#c3d3b2' },
    text: {
      primary: '#eeeeee',
      secondary: '#648462',
    },
    background: {
      default: '#222222',
      paper: '#333333',
    },
  },
};

const mistyMountainsTheme = {
  palette: {
    type: 'dark',
    primary: { main: '#362f40' },
    secondary: { main: '#8864c1' },
    tertiary: { main: '#c3d3b2aa' },
    error: { main: '#ff174d' },
    text: {
      primary: '#eeeeee',
      secondary: '#4cc973',
    },
    background: {
      default: '#222222',
      paper: '#aaaaaa05',
      paperOpaque: '#aaaaaa25',
    },
  },
  shadows: [
    '0px 0px 16px 5px rgba(0,0,0,0)',
    '0px 0px 16px 5px rgba(0,0,0,0.100)',
    '0px 0px 16px 5px rgba(0,0,0,0.133)',
    '0px 0px 16px 5px rgba(0,0,0,0.166)',
    '0px 0px 16px 5px rgba(0,0,0,0.200)',
    '0px 0px 16px 5px rgba(0,0,0,0.233)',
    '0px 0px 16px 5px rgba(0,0,0,0.266)',
    '0px 0px 16px 5px rgba(0,0,0,0.300)',
    '0px 0px 16px 5px rgba(0,0,0,0.333)',
    '0px 0px 16px 5px rgba(0,0,0,0.400)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
    '0px 0px 16px 5px rgba(0,0,0,0.35)',
  ],
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundImage: 'url(' + mountains + ')',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
};

const common = {
  typography: {
    fontSize: 13,
  },
  drawer: {
    width: 310,
  },
};

const themes = {
  light: { ...lightTheme, ...common },
  zen: { ...zenTheme, ...common },
  dark: { ...darkTheme, ...common },
  mistyMountains: { ...mistyMountainsTheme, ...common },
};

export default themes;
