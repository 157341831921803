import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Material UI
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../contexts/AppStateContext';
import { StoreContext } from '../../contexts/StoreContext';
import Header from '../../components/Header/Header';
// import Login from '../../views/Login/Login';
// import SignUp from '../../views/SignUp/SignUp';
import Dashboard from '../../views/Dashboard/Dashboard';
import Goals from '../../views/Goals/Goals';
import Projects from '../../views/Projects/Projects';
import Actions from '../../views/Actions/Actions';
import Calendar from '../../views/Calendar/Calendar';
import Reports from '../../views/Reports/Reports';
import themes from '../../themes';

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress className={classes.progress} color="secondary" />
    </div>
  );
};

const Error = ({ error }) => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <p>Oops! Something went wrong: {error}</p>
    </div>
  );
};

const Lyfeplan = () => {
  const classes = useStyles();
  const { loggedIn } = useContext(AppStateContext);
  const { loading, error, profile } = useContext(StoreContext);

  if (loading || !profile) return <Loading />;

  //const theme = createMuiTheme(themes[profile?.theme || 'zen']);
  const theme = createMuiTheme(themes['mistyMountains']);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.flex}>
        <Header />
        {loading && <Loading />}
        {error && <Error error={error} />}
        {!loading && !error ? (
          loggedIn ? (
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/goals" component={Goals} />
              <Route path="/projects" component={Projects} />
              <Route path="/actions" component={Actions} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/reports" component={Reports} />
              <Redirect to="/dashboard" />
            </Switch>
          ) : (
            <Switch>
              {/* <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} /> */}
              <Redirect to="/login" />
            </Switch>
          )
        ) : null}
      </div>
    </ThemeProvider>
  );
};

export default Lyfeplan;
