import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  h5: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  timeScopeTitleSmall: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
  },
  overdue: {
    color: theme.palette.error.main,
    flexGrow: 1,
  },
  reschedule: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
  },
}));
