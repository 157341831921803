import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  displayedAction: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  strike: {
    textDecoration: 'line-through',
    color: theme.palette.grey[500],
  },
  empty: {
    color: theme.palette.grey[500],
  },
  duration: {
    fontSize: '.9rem',
    color: theme.palette.grey[500],
  },
}));
