import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

// Material UI
import { SwipeableDrawer, List, ListItem, Divider } from '@material-ui/core';

// Lyfeplan
import { AppStateContext } from '../../contexts/AppStateContext';
import useStyles from './styles';
import Logo from '../Logo/Logo';
import ProfileMenu from '../ProfileMenu/ProfileMenu';

const AppDrawer = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const classes = useStyles(isTabletOrMobile);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { drawerOpen, updateAppState } = useContext(AppStateContext);
  // TODO: import userLogout
  const userLogout = () => {};

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    updateAppState({ drawerOpen: open });
  };

  return (
    <SwipeableDrawer
      className={classes.drawer}
      variant={isTabletOrMobile ? 'temporary' : 'permanent'}
      classes={{ paper: classes.drawerPaper }}
      open={drawerOpen || false}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      {isTabletOrMobile ? (
        <>
          <List>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Logo />
              <ProfileMenu userLogout={() => userLogout()} />
            </ListItem>
          </List>
          <Divider />
        </>
      ) : null}

      {children}
    </SwipeableDrawer>
  );
};

export default AppDrawer;
