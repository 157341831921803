// Version
export const APP_VERSION = '0.6.7';

// Statuses
export const STATUSES = {
  toAchieve: {
    label: 'To achieve',
    color: '#aaaaaa',
  },
  inProgress: {
    label: 'In progress',
    color: '#66b78e',
  },
  done: {
    label: 'Done',
    color: '#008744',
  },
  notDone: {
    label: 'Not done',
    color: '#d62d20',
  },
  improve: {
    label: 'Need to improve',
    color: '#ffa700',
  },
};
