import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';

// Material UI
import { IconButton } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

// Lyfeplan
import { StoreContext } from '../../contexts/StoreContext';
import useMenu from '../../hooks/useMenu';

const ProfileMenu = () => {
  const { cleanUp } = useContext(StoreContext);

  // TODO: import AWS logout function
  const userLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const { menu, onMenuClick } = useMenu([
    // { label: 'Zen Theme', onClick: () => updateProfile({ theme: 'zen' }) },
    // { label: 'Light Theme', onClick: () => updateProfile({ theme: 'light' }) },
    // { label: 'Dark Theme', onClick: () => updateProfile({ theme: 'dark' }) },
    // { label: 'Misty Mountains Theme', onClick: () => updateProfile({ theme: 'mistyMountains' }) },
    { label: 'Clean Up', onClick: () => cleanUp() },
    { label: 'Logout', onClick: () => userLogout() },
  ]);

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="Profile Menu" onClick={onMenuClick}>
        <PersonIcon />
      </IconButton>
      {menu}
    </>
  );
};

export default ProfileMenu;
