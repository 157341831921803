import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

// Material UI
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../contexts/AppStateContext';
import Logo from '../Logo/Logo';
import LoggedOutMenu from './LoggedOutMenu/LoggedOutMenu';
import LoggedInMenu from './LoggedInMenu/LoggedInMenu';

const Header = () => {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const { loggedIn, updateAppState } = useContext(AppStateContext);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* Menu icon */}
        {isTabletOrMobile ? (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Main Menu"
            onClick={() => updateAppState({ drawerOpen: true })}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Logo />
        )}

        {/* Tab menu */}
        {loggedIn ? <LoggedInMenu isTabletOrMobile={isTabletOrMobile} /> : <LoggedOutMenu />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
