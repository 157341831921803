import React, { useState } from 'react';

// Material UI
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    backdropFilter: 'blur(30px)',
  },
}));

const useMenu = (items) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onMenuClick = (event) => setMenuAnchorEl(event.currentTarget);
  const onMenuClose = () => setMenuAnchorEl(null);

  const menu = (
    <Menu
      anchorEl={menuAnchorEl}
      keepMounted
      open={Boolean(menuAnchorEl)}
      onClose={onMenuClose}
      classes={{ paper: classes.menu }}
    >
      {Array.isArray(items) &&
        items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              onMenuClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
    </Menu>
  );

  return { menu, isMenuOpen: !!menuAnchorEl, onMenuClick, onMenuClose };
};

export default useMenu;
