import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { DataStore, Hub } from 'aws-amplify';

// Material UI
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Lyfeplan
import ServiceWorker from './components/ServiceWorker/ServiceWorker';
import AppStateContextProvider from './contexts/AppStateContext';
import StoreContextProvider from './contexts/StoreContext';
import Lyfeplan from './containers/Lyfeplan/Lyfeplan';

// Handle onAuthUIStateChange
onAuthUIStateChange((newAuthState) => {
  // console.log('onAuthUIStateChange:', newAuthState);

  if (newAuthState === 'signin') {
    console.log('Clearing DataStore');
    DataStore.clear();
  }
});

// Handle Hub auth events
Hub.listen('auth', async (data) => {
  // console.log('Hub event:', data.payload.event);

  if (data.payload.event === 'signOut') {
    console.log('Clearing DataStore');
    await DataStore.clear();
  }
});

const App = () => {
  return (
    <div className="App">
      <ServiceWorker />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AppStateContextProvider>
          <StoreContextProvider>
            <Lyfeplan />
          </StoreContextProvider>
        </AppStateContextProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withAuthenticator(App);
