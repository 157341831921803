import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { Button } from '@material-ui/core';

const LoggedOutMenu = () => (
  <>
    <div style={{ flexGrow: 1 }} />
    <Button color="inherit" component={Link} to="login">
      Login
    </Button>
    <Button color="inherit" component={Link} to="signup">
      Sign Up
    </Button>
  </>
);

export default LoggedOutMenu;
