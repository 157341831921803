import React, { useState, useContext } from 'react';
import moment from 'moment';

// Material UI
import { Divider, Button, IconButton } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { StoreContext } from '../../../contexts/StoreContext';
import useMenu from '../../../hooks/useMenu';
import CustomCard from '../../UI/CustomCard/NewCustomCard';
import DroppableList from '../../UI/DroppableList/DroppableList';
import DraggableListItem from '../../UI/DroppableList/DraggableListItem/DraggableListItem';
import Action from '../Action/Action';
import BlockDatePicker from '../BlockDatePicker/BlockDatePicker';

const CaptureBlock = ({ blockId, dragHandleProps, showDate }) => {
  const classes = useStyles();
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const { minimizeBlocks } = useContext(AppStateContext);
  const { getBlock, addAction, updateBlock, deleteBlock } = useContext(StoreContext);

  const { menu, onMenuClick } = useMenu([
    { label: 'Schedule', onClick: () => {} },
    { label: 'Toggle completed', onClick: () => onBlockToggleCompleted(blockId) },
    { label: 'Hide completed', onClick: () => {} },
    { label: 'Delete', onClick: () => deleteBlock(blockId) },
  ]);

  const block = getBlock(blockId);
  if (!block) return null;

  const onBlockToggleCompleted = () => {
    updateBlock(block, { completedDate: block.completedDate ? null : moment().format() });
  };

  return (
    <CustomCard
      topBarColor="grey"
      title="Capture"
      options={
        <>
          {/* Date picker */}
          <BlockDatePicker
            blockId={blockId}
            block={block}
            showDate={showDate}
            isOpenSchedule={isOpenSchedule}
            setIsOpenSchedule={setIsOpenSchedule}
            updateBlock={updateBlock}
          />

          {/* Menu button */}
          <IconButton color="inherit" onClick={onMenuClick}>
            <MoreVertIcon />
          </IconButton>
          {menu}

          {/* Drag handle */}
          {dragHandleProps && (
            <div {...dragHandleProps} className={classes.dragHandleIcon}>
              <DragHandleIcon style={{ display: 'block' }} />
            </div>
          )}
        </>
      }
      noPadding
      minimized={minimizeBlocks}
    >
      {/* Action items */}
      {!minimizeBlocks && (
        <>
          <Divider />
          <DroppableList droppableId={blockId} droppableType="action" outerDragDropContext>
            {block.actionIds &&
              block.actionIds.map((actionId, index) => (
                <DraggableListItem key={actionId} draggableId={actionId} index={index}>
                  <Action actionId={actionId} />
                </DraggableListItem>
              ))}
          </DroppableList>
        </>
      )}

      <div className={classes.cardPadding}>
        {/* Add action button */}
        <Button size="small" onClick={() => addAction({ blockId })}>
          <AddIcon className={classes.leftIcon} /> Add Action
        </Button>
      </div>
    </CustomCard>
  );
};

export default CaptureBlock;
