import React, { useState, useContext } from 'react';

// Material UI
import { Tooltip, IconButton, TextField } from '@material-ui/core';
import {
  DoneOutlined as DoneIcon,
  CancelOutlined as CancelIcon,
  Person as PersonIcon,
} from '@material-ui/icons';

// Lyfeplan
import useStyles from './styles';
import { StoreContext } from '../../../../contexts/StoreContext';
import CustomDatePicker from '../../../UI/CustomDatePicker/CustomDatePicker';
import DurationPicker from '../../../UI/DurationPicker/DurationPicker';

const EditableAction = ({ action, scheduledDate, setEditing, setScheduledDate, onAddAction }) => {
  const classes = useStyles();
  const { updateAction } = useContext(StoreContext);
  const [name, setName] = useState(action.name);
  const [duration, setDuration] = useState(action.duration || null);

  const applyChange = () => {
    const props = {
      name: name,
      duration: duration,
      scheduledDate,
    };

    if (action.id) {
      setEditing(false);
      updateAction(action, props);
    } else if (onAddAction) {
      onAddAction(props);
      reset();
    }
  };

  const cancelChange = () => {
    setName(action.name);
    if (onAddAction) {
      onAddAction(false);
      reset();
    } else {
      setEditing(false);
    }
  };

  const reset = () => {
    setName('');
    setDuration(null);
    setScheduledDate(null);
  };

  return (
    <div className={classes.editableAction}>
      <div>
        {/* Action name */}
        <TextField
          type="textarea"
          autoFocus
          multiline
          fullWidth
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputInput,
            },
          }}
          placeholder="Action, todo, idea..."
          value={name || ''}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(event) => {
            if ((event.ctrlKey || event.shiftKey) && event.key === 'Enter') {
              return;
            }
            if (event.key === 'Enter') {
              event.preventDefault();
              applyChange();
            }
            if (event.key === 'Escape') {
              event.preventDefault();
              cancelChange();
            }
          }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {/* Apply button */}
          <Tooltip title="Apply (Enter)" placement="top">
            <IconButton onClick={() => applyChange()}>
              <DoneIcon />
            </IconButton>
          </Tooltip>

          {/* Cancel button */}
          <Tooltip title="Cancel (Esc)" placement="top">
            <IconButton onClick={() => cancelChange()}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ display: 'flex' }}>
          {/* Duration input*/}
          <Tooltip title="Duration" placement="top">
            <span>
              <DurationPicker
                duration={action.duration}
                showDuration
                onChange={(momentDuration) => setDuration(momentDuration?.asMinutes() || null)}
              />
            </span>
          </Tooltip>

          {/* Date picker */}
          <Tooltip title="Scheduled Date" placement="top">
            <span>
              <CustomDatePicker
                date={scheduledDate}
                showDate
                onChange={(momentDate) => setScheduledDate(momentDate ? momentDate.format() : null)}
              />
            </span>
          </Tooltip>

          {/* Delegate picker */}
          <Tooltip title="Delegate" placement="top">
            <IconButton>
              <PersonIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default EditableAction;
